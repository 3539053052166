import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { gql } from '@apollo/client';
import {
  FdLoadingSpinner,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdButton,
} from '@fifthdomain/fe-shared';
import { listTasksByUserId } from '../../../graphql/queries';
import LabelWithTooltip from '../LabelWithTooltip';
import ChallengeOverview from './ChallengeOverview';
import HighlightedLabel from '../HighlightedLabel';

const CreatedChallenges = () => {
  const globalSnap = useSnapshot(globalStore);
  const [challengeOverview, setChallengeOverview] = useState(false);

  const { data: listTasksData, loading: listTasksLoading } = useQueryRecursive(
    gql(listTasksByUserId),
    {
      variables: {
        userId: globalSnap.userId,
        limit: 1000,
      },
      skip: !globalSnap.userId,
    },
  );

  if (listTasksLoading) {
    return <FdLoadingSpinner />;
  }

  const createdChallenges =
    listTasksData?.listTasksByUserId?.items?.filter(
      (t) => t?.type !== 'CONTAINER',
    ) || [];

  return (
    createdChallenges?.length > 0 && (
      <FdCard
        elevation={0}
        heading={
          <Box display="flex" justifyContent="space-between">
            <LabelWithTooltip
              label="Challenge Contributions"
              tooltipText="In this section, you will find a list of all the challenges you have created on FifthDomain platform."
              labelVariant="body1"
            />
            <FdButton
              size="small"
              variant="secondary"
              onClick={() => {
                setChallengeOverview(true);
              }}
            >
              View
            </FdButton>
          </Box>
        }
      >
        <Box className="flex justify-center items-center w-8 h-8 rounded-full ml-3">
          <HighlightedLabel value={createdChallenges?.length || 0} />
        </Box>
        <ChallengeOverview
          openModal={challengeOverview}
          onDismiss={() => setChallengeOverview(false)}
        />
      </FdCard>
    )
  );
};

export default CreatedChallenges;
