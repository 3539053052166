import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import {
  FdLoadingSpinner,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTab,
} from '@fifthdomain/fe-shared';
import { listTasksByUserId } from '../../../graphql/queries';
import ChallengeListTable from './ChallengeListTable';
import ChallengesComposition from './ChallengesComposition';
import { PROFICIENCY_LEVELS } from '../../../constants';
import { getDifficultyLevel } from '../../../shared/utils/difficultyMapping';

const ChallengeOverview = ({ openModal, onDismiss }) => {
  const globalSnap = useSnapshot(globalStore);

  const { data: listTasksData, loading: listTasksLoading } = useQueryRecursive(
    gql(listTasksByUserId),
    {
      variables: {
        userId: globalSnap.userId,
        limit: 1000,
      },
      skip: !globalSnap.userId,
    },
  );
  const allTasksCreated =
    listTasksData?.listTasksByUserId?.items
      ?.filter((t) => t?.type !== 'CONTAINER')
      ?.map((item) => ({
        ...item,
        specialties: item?.specialty?.name,
        difficultyLabel:
          item?.difficulty > 5
            ? getDifficultyLevel(item?.difficulty)
            : PROFICIENCY_LEVELS[item?.difficulty],
      })) || [];

  if (listTasksLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <FdModal
      size="lg"
      showClose
      title="Challenge Contribution Overview"
      description='Below, you will find all the challenges you have created. The "Challenge Composition" tab provides insights into the diversity of your challenges, categorised by Professional Specialties, Proficiency Levels, and Types.'
      open={openModal}
      onDismiss={() => {
        onDismiss();
      }}
      data-cy="open-modal"
    >
      <FdTab
        label={[
          {
            label: 'Challenges List',
            index: 0,
            data: <ChallengeListTable rows={allTasksCreated} />,
          },
          {
            label: 'Challenges Composition',
            index: 1,
            data: <ChallengesComposition allTasksCreated={allTasksCreated} />,
          },
        ]}
      />
    </FdModal>
  );
};

ChallengeOverview.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default ChallengeOverview;
