import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdTypography, FdSkeleton } from '@fifthdomain/fe-shared';

const SummaryTitle = ({
  data: { value, description, icon },
  titleVariant,
  subtitleVariant,
  justifyContent,
  loading,
}) => (
  <FdSkeleton loading={loading} width="100px" height="72px">
    <Box display="flex" justifyContent="center" alignItems="center">
      {icon && <Box mr={2}>{icon}</Box>}
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent={justifyContent} color="primary">
          <FdTypography variant={titleVariant}>{value}</FdTypography>
        </Box>
        {description && (
          <Box
            display="flex"
            justifyContent={justifyContent}
            height="36px"
            mt={1}
          >
            <FdTypography variant={subtitleVariant} color="secondary">
              {description}
            </FdTypography>
          </Box>
        )}
      </Box>
    </Box>
  </FdSkeleton>
);

SummaryTitle.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]),
    description: PropTypes.string,
    icon: PropTypes.element,
  }).isRequired,
  titleVariant: PropTypes.string,
  subtitleVariant: PropTypes.string,
  justifyContent: PropTypes.string,
  loading: PropTypes.bool,
};

SummaryTitle.defaultProps = {
  titleVariant: 'subtitle1',
  subtitleVariant: 'body2',
  justifyContent: 'center',
  loading: false,
};

export default SummaryTitle;
