export const USER_PERMISSION_TYPE = Object.freeze({
  CREATE: 'CREATE',
  VIEW_INSIGHTS: 'VIEW_INSIGHTS',
  MANAGE_TRAINING: 'MANAGE_TRAINING',
  MANAGE_GROUPS: 'MANAGE_GROUPS',
  MANAGE_PARTICIPANTS: 'MANAGE_PARTICIPANTS',
  MANAGE_MANAGERS: 'MANAGE_MANAGERS',
});

export const THEMES = Object.freeze([
  { value: 'LIGHT', label: 'FD Pro Light' },
  { value: 'DARK', label: 'FD Pro Dark' },
]);

export const SPECIALTY_COLOR = {
  Intelligence: '#A328ED',
  Protection: '#4E4EFF',
  Detection: '#C849A4',
  Investigation: '#3EB3D6',
  Penetration: '#97B1FF',
  Engineering: '#A4B8D1',
};

export const PROFICIENCY = {
  Novice: {
    color: '#BAE8D2',
    range: '1',
    completeColor: '#76D1A5',
  },
  'Advanced Beginner': {
    color: '#CBD9FF',
    range: '2',
    completeColor: '#97B4FF',
  },
  Competent: {
    color: '#FADCB8',
    range: '3',
    completeColor: '#F6B971',
  },
  Proficient: {
    color: '#F0BFDD',
    range: '4',
    completeColor: '#E380BB',
  },
  Expert: {
    color: '#EAA9A9',
    range: '5',
    completeColor: '#D55353',
  },
};
export const ORG_RANGE = Object.freeze({
  RANGE_1_10: '1-10',
  RANGE_11_50: '11-50',
  RANGE_51_100: '51-100',
  RANGE_101_250: '101-250',
  RANGE_251_500: '250-500',
  RANGE_500: '500+',
});

export const ORG_SECTOR = Object.freeze({
  FINANCE: 'Finance',
  DEFENCE: 'Infrastructure', // infrastructure is mapped to defence
  INTELLIGENCE: 'Intelligence',
  HEALTHCARE: 'Healthcare',
  EDUCATION: 'Education',
  GOVERNMENT_PUBLIC_ADMINISTRATION: 'Government/Public Administration',
  INFORMATION_TECHNOLOGY: 'Information Technology',
  LAW_ENFORCEMENT: 'Law Enforcement',
  MANUFACTURING: 'Manufacturing',
  RETAIL_ECOMMERCE: 'Retail/E-Commerce',
  RECRUITMENT: 'Recruitment',
  CONSULTING: 'Consulting',
  OTHER_ORG_SECTOR: 'Other(Please Specify)',
});

export const WORK_STATUS = Object.freeze({
  AUSTRALIAN_CITIZEN: 'Australian Citizen',
  PERMANENT_RESIDENT_OR_NEW_ZEALAND_CITIZEN:
    'Permanent Resident (PR) and/or New Zealand Citizen',
  FAMILY_PARTNER_VISA_WITH_NO_WORKING_RESTRICTIONS:
    'Family/Partner Visa with No Working Restrictions',
  TEMPORARY_VISA_WITH_NO_WORKING_RESTRICTIONS:
    'Temporary Visa with No Working Restrictions',
  TEMPORARY_VISA_WITH_WORKING_HOUR_RESTRICTIONS:
    'Temporary Visa with Working Hour Restrictions',
  TEMPORARY_VISA_WITH_WORKING_LOCATION_RESTRICTIONS:
    'Temporary Visa with Working Location Restrictions',
  TEMPORARY_VISA_WITH_WORKING_INDUSTRY_RESTRICTIONS:
    'Temporary Visa with Working Industry Restrictions',
  TEMPORARY_SAFE_HAVEN_PROTECTION_WORK_VISA:
    'Temporary Safe Haven/Protection Work Visa',
  TEMPORARY_GRADUATE_WORK_VISA: 'Temporary Graduate Work Visa',
  TEMPORARY_HOLIDAY_WORK_VISA: 'Temporary Holiday Work Visa',
  REQUIRES_SPONSORSHIP_FOR_EMPLOYMENT_RIGHTS:
    'Requires Sponsorship for Employment Rights',
});

export const GENDER = Object.freeze({
  MALE: 'Male',
  FEMALE: 'Female',
  NON_BINARY: 'Non-Binary',
  OTHER: 'Other',
  PREFER_NOT_TO_SPECIFY: 'Prefer not to specify',
});

export const NEURODIVERSITY = Object.freeze({
  YES: 'Yes, I identify as neurodiverse',
  NO: 'No, I do not identify as neurodiverse',
  NOT_TO_SPECIFY: 'Prefer not to specify',
});

export const ETHNICITY = Object.freeze({
  ABORIGINAL_TORRES_STRAIT_ISLANDER: 'Aboriginal/Torres Strait Islander',
  AFRICAN: 'African',
  ASIAN: 'Asian',
  CAUCASIAN: 'Caucasian',
  LATIN_AMERICAN: 'Latin American',
  MIDDLE_EASTERN: 'Middle Eastern',
  MIXED_MULTIRACIAL: 'Mixed/Multiracial',
  PACIFIC_ISLANDER: 'Pacific Islander',
  OTHER: 'Other',
  PREFER_NOT_TO_SPECIFY: 'Prefer not to specify',
});

export const RURAL_REMOTE = Object.freeze({
  YES: 'Yes, I am in a rural/remote location',
  NO: 'No, I am not in a rural/remote location',
  NOT_TO_SPECIFY: 'Prefer not to specify',
});

export const WORKING_ARRANGEMENT = Object.freeze({
  ON_SITE: 'On-Site',
  REMOTE: 'Remote',
  HYBRID: 'Hybrid (On-Site and Remote)',
});

export const PROFILE_DEFAULT_VALUES = [
  'No Occupation Provided',
  'No Location Provided',
  'No Right to Work Status Provided',
  'Not Provided',
  'No link provided',
  'Not Selected',
];

export const PROFILE_VIEW_VALUES = [
  { value: 'linkedIn', label: 'LinkedIn' },
  {
    value: 'gitlab',
    label: 'GitHub/GitLab/Other Contribution Platform',
  },
  { value: 'website', label: 'Website/Portfolio' },
  { value: 'gender', label: 'Gender' },
  { value: 'neurodiversity', label: 'Neurodiversity' },
  { value: 'ethnicity', label: 'Ethnicity' },
  { value: 'remote', label: 'Rural/Remote' },
  { value: 'workArrangement', label: 'Preferred Working Arrangement' },
  { value: 'relocate', label: 'Willingness to Relocate' },
  { value: 'salaryRange', label: 'Salary Expectations' },
];

export const getToolTipTitle = (_heading) => {
  switch (_heading) {
    case 'Resume':
      return 'Upload a resume to help employers learn more about your experiences. A well-crafted resume can enhance your profile and increase your chances of securing suitable opportunities.';
    case 'Social Media':
      return 'Connect your social media profiles to help employers learn more about your professional achievements, projects, and networking.';
    case 'Job Pool':
      return 'Opting in to this means that employers will be able to find you in candidate searches, view your profile, and invite you to apply for their jobs. You will still be able to browse and apply for jobs yourself if you choose to opt out.';
    case 'Diversity':
      return 'Diversity information is anonymously used for reporting purposes, and is never used for job recommendations or eligibility. Employers cannot see this information.';
    case 'Logistics':
      return 'This section provides important information to prospective employers about your working logistics and expectations.';
    default:
      return '';
  }
};

export const ORG_PRODUCTS = {
  Labs: 'Course',
  Assessor: 'Assessment',
  Competitions: 'Competition',
};

export const PROFICIENCY_LEVELS = {
  1: 'Novice',
  2: 'Advanced Beginner',
  3: 'Competent',
  4: 'Proficient',
  5: 'Expert',
};

export const TEAM_ROLES = {
  TEAM_MANAGER: 'TEAM_MANAGER',
  GENERAL_MEMBER: 'GENERAL_MEMBER',
};

export const EVENT_AVAILABILITY_TYPES = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC',
};

export const ACCOMPLISHMENT_TYPES = Object.freeze({
  EDUCATION: 'Education/Course',
  CERTIFICATIONS: 'Certification',
  OTHER: 'Other Accomplishment',
});
export const CONTENT_IMAGES = [
  'Analysis.jpg',
  'Intrusion Detection.jpg',
  'Incident Management.jpg',
  'Vulnerability.jpg',
  'Custom.jpg',
];

// skills performance timeline data starts from this date - week 1 of October 2023
export const SKILLS_PERFORMANCE_TIMELINE_START_DATE = new Date(2023, 9, 1);
