import { Storage } from 'aws-amplify';

export const getImageUrlFromS3 = async (name) => {
  try {
    const image = await Storage.get(name);
    return image;
  } catch (error) {
    return error.message;
  }
};

export const getPreSignedUrl = async (key) => {
  const url = await Storage.get(key, { level: 'public' });
  return url;
};
