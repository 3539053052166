import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BasePage,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import * as Yup from 'yup';
import { getOrg } from '../graphql/queries';
import { OrgDetails, OrgInformation } from '../components/OrganisationProfile';
import { ORG_RANGE, ORG_SECTOR } from '../constants';
import { getValueByKey } from '../shared/utils/objectUtils';

const initialValues = {
  name: '',
  alias: '',
  websiteLink: '',
  linkedinLink: '',
  about: '',
  orgRange: '',
  orgSector: '',
  orgSectorOther: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a name.')
    .max(150, 'Name cannot be more than 150 characters long.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please enter a name.',
      (value) => value && value.length === value.trim().length,
    ),
  websiteLink: Yup.string()
    .nullable()
    .max(300, 'Link should not be more than 300 characters'),
  linkedinLink: Yup.string()
    .nullable()
    .max(300, 'Link should not be more than 300 characters'),
  about: Yup.string()
    .nullable()
    .max(256, 'Organisation description should be less than 256 characters.'),
  orgSectorOther: Yup.string()
    .nullable()
    .max(120, 'Sector name should be less than 120 characters.'),
});

const OrganisationProfile = () => {
  const globalSnap = useSnapshot(globalStore);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset } = reactHookFormMethods;

  const { loading: orgLoading } = useQuery(gql(getOrg), {
    variables: {
      id: globalSnap?.orgId,
    },
    skip: !globalSnap?.orgId,
    onCompleted: (data) => {
      const orgData = data?.getOrg;

      reset({
        ...orgData,
        orgRange: getValueByKey(ORG_RANGE, orgData?.orgRange),
        orgSector: getValueByKey(ORG_SECTOR, orgData?.orgSector),
      });
    },
  });

  if (orgLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <BasePage heading="Organisation Details">
      <FormProvider {...reactHookFormMethods}>
        <form>
          <OrgDetails />
          <OrgInformation />
        </form>
      </FormProvider>
    </BasePage>
  );
};

export default OrganisationProfile;
