import * as Yup from 'yup';

// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test({
    name: 'noWhitespace',
    message,
    test: (value) => (value ? value.trim().length : true),
  });
});

Yup.addMethod(Yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array) {
    const uniqueData = Array.from(
      new Set(array?.map((row) => row[field]?.toLowerCase())),
    );
    // check isUnique only if field is defined or else return true
    const isUnique =
      uniqueData[0] !== undefined ? array.length === uniqueData?.length : true;

    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i],
    );
    if (index > 0) {
      if (array[index][field] === '') {
        return true;
      }
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

export default Yup;
