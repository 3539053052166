export const upperCaseFirstLetter = (str) =>
  str && str[0].toUpperCase() + str.substring(1).toLowerCase();

export const initCaps = (str, replaceUnderscores = false) => {
  let modifiedStr = str;
  if (replaceUnderscores) {
    modifiedStr = modifiedStr.replace(/_/g, ' ');
  }
  if (!modifiedStr) return '';
  return modifiedStr
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export const stringToColor = (str) => {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substring(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};

export const getAvatarName = (name = '', twoLetters = false) => {
  const splittedName = name.toString().toUpperCase().split(' ');
  return twoLetters
    ? splittedName.length > 1
      ? splittedName[0].charAt(0) + splittedName[1].charAt(0)
      : splittedName[0].charAt(0)
    : splittedName[0].charAt(0);
};

export const getCommaSeparated = (arr, includeQuotes = false) => {
  const arrQ = includeQuotes ? arr.map((a) => `"${a}"`) : arr;
  if (arrQ.length === 1) return arrQ[0];
  const firsts = arrQ.slice(0, arrQ.length - 1);
  const last = arrQ[arrQ.length - 1];
  return `${firsts.join(', ')} and ${last}`;
};

export const getSearchResults = (data, text) =>
  text === ''
    ? data
    : data?.filter((_content) =>
        Object.values(_content)
          .filter((item) => item !== null && item !== undefined && item !== '')
          .some((val) =>
            val.toString().toLowerCase().startsWith(text.toLowerCase()),
          ),
      );

export const snakeCaseToTitleCase = (str) => {
  return (
    str
      ?.replace('_', ' ')
      ?.toLowerCase()
      ?.replace(/\b\w/g, (c) => c.toUpperCase()) || ''
  );
};
