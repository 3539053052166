import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import { FdCard } from '@fifthdomain/fe-shared';
import DonutWithLegends from '../../Charts/DonutWithLegends';
import { PROFICIENCY, SPECIALTY_COLOR } from '../../../constants';
import { upperCaseFirstLetter } from '../../../shared/utils/stringUtils';
import { TASK_TYPE_COLOR } from '../../../shared/utils/taskUtils';

const ChallengesComposition = ({ allTasksCreated }) => {
  const specialtyRows = _(allTasksCreated?.filter((item) => item?.specialtyId))
    ?.groupBy('specialtyId')
    ?.map((items, specialtyId) => ({
      id: specialtyId,
      label: items[0]?.specialty?.name,
      value: items?.length || 0,
      color: SPECIALTY_COLOR[items[0]?.specialty?.name] || '',
    }))
    .value();
  const proficiencyRows = _(
    allTasksCreated?.map((item) => ({
      ...item,
      proficiency: item?.difficultyLabel,
    })),
  )
    ?.sort((a, b) => a?.difficulty - b?.difficulty)
    ?.groupBy('proficiency')
    ?.map((items, proficiency) => ({
      id: proficiency,
      label: proficiency,
      value: items?.length || 0,
      color: PROFICIENCY[proficiency]?.color || '',
    }))
    .value();

  const typeRows = _(allTasksCreated)
    ?.groupBy('type')
    ?.map((items, type) => ({
      id: type,
      label: upperCaseFirstLetter(type),
      value: items?.length || 0,
      color: TASK_TYPE_COLOR[upperCaseFirstLetter(type)] || '',
    }))
    .value();

  return (
    <Box height="490px">
      <Box display="flex">
        <Box width="50%" mr={2}>
          <FdCard>
            <DonutWithLegends
              title="Professional Specialty composition"
              valueSegment="specialty"
              data={specialtyRows}
              colors={specialtyRows?.map((s) => s.color)}
              totalTasks={String(allTasksCreated?.length)}
            />
          </FdCard>
        </Box>
        <Box width="48%">
          <FdCard>
            <DonutWithLegends
              title="Proficiency composition"
              valueSegment="proficiency"
              data={proficiencyRows}
              colors={proficiencyRows?.map((s) => s?.color)}
              totalTasks={String(allTasksCreated?.length)}
            />
          </FdCard>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt={1}>
        <Box width="50%">
          <FdCard>
            <DonutWithLegends
              title="Type composition"
              valueSegment="type"
              data={typeRows}
              colors={typeRows?.map((s) => s?.color)}
              totalTasks={String(allTasksCreated?.length)}
            />
          </FdCard>
        </Box>
      </Box>
    </Box>
  );
};

ChallengesComposition.propTypes = {
  allTasksCreated: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

ChallengesComposition.defaultProps = {
  allTasksCreated: [],
};

export default ChallengesComposition;
