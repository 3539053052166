import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import Chart from 'react-apexcharts';

const Radar = ({ data, categories, chartId, ...props }) => {
  const theme = useTheme();
  const options = {
    series: data,
    options: {
      chart: {
        id: chartId,
        height: 450,
        type: 'radar',
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
        toolbar: {
          show: false,
        },
        background: 'transparent',
      },
      xaxis: {
        categories,
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
          offsetX: 15,
        },
      },
      legend: {
        offsetY: -30,
        formatter: (seriesName) => [seriesName, '%'],
      },
      tooltip: {
        followCursor: true,
        y: {
          formatter: (value) => `${value}%`,
        },
      },
      markers: {
        size: 8,
        radius: 5,
        hover: {
          size: 10,
        },
      },
      theme: {
        mode: theme?.palette?.type || 'light',
      },
    },
  };

  return (
    <Box width="100%">
      <Chart
        options={options.options}
        series={options.series}
        type="radar"
        height="650"
        width="100%"
        {...props}
      />
    </Box>
  );
};

Radar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    }),
  ),
  categories: PropTypes.arrayOf(PropTypes.string),
  chartId: PropTypes.string,
};

Radar.defaultProps = {
  data: [],
  categories: [],
  chartId: 'radar-chart',
};

export default Radar;
