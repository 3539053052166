import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useMutation, gql } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdTextField,
  FdTypography,
  FdButton,
  FdSelect,
  FdIconsV5,
} from '@fifthdomain/fe-shared';
import {
  warningToastMessage,
  successToastMessage,
  errorToastMessage,
} from '../../shared/utils/toast';
import { updateOrg } from '../../graphql/mutations';
import { ORG_RANGE, ORG_SECTOR } from '../../constants';
import { getKeyByValue, getValueByKey } from '../../shared/utils/objectUtils';

const OrgInformation = () => {
  const [editMode, setEditMode] = useState(false);
  const orgDefaultValue =
    'Optional: Click “Edit” if you wish to add this detail.';
  const { ContentCopy } = FdIconsV5;
  const { control, getValues, reset, watch } = useFormContext();
  const orgSectorWatch = watch('orgSector');
  const [updateOrgMutation, { loading: updateOrgLoading }] = useMutation(
    gql(updateOrg),
    {
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  return (
    <FdCard
      heading={
        <Box display="flex" justifyContent="space-between">
          <FdTypography variant="h3">Organisation Information</FdTypography>
          {editMode ? (
            <Box display="flex">
              <FdButton
                variant="secondary"
                size="small"
                onClick={() => {
                  reset();
                  setEditMode(false);
                  warningToastMessage('Updates not saved');
                }}
                style={{
                  marginRight: '8px',
                }}
              >
                CANCEL
              </FdButton>
              <FdButton
                variant="primary"
                size="small"
                onClick={async () => {
                  const {
                    id,
                    websiteLink,
                    linkedinLink,
                    about,
                    orgRange,
                    orgSector,
                    orgSectorOther,
                  } = getValues();
                  await updateOrgMutation({
                    variables: {
                      input: {
                        id,
                        websiteLink: websiteLink || null,
                        linkedinLink: linkedinLink || null,
                        about: about || null,
                        orgRange:
                          getKeyByValue(ORG_RANGE, orgRange) || undefined,
                        orgSector:
                          getKeyByValue(ORG_SECTOR, orgSector) || undefined,
                        orgSectorOther:
                          orgSector === ORG_SECTOR.OTHER_ORG_SECTOR
                            ? orgSectorOther
                            : '',
                      },
                    },
                    onCompleted: (_data) => {
                      reset({
                        ..._data?.updateOrg,
                        orgRange: getValueByKey(
                          ORG_RANGE,
                          _data?.updateOrg?.orgRange,
                        ),
                        orgSector: getValueByKey(
                          ORG_SECTOR,
                          _data?.updateOrg?.orgSector,
                        ),
                      });
                      successToastMessage('Updates saved!');
                    },
                  });
                  setEditMode(false);
                }}
              >
                {updateOrgLoading ? 'SAVING...' : 'SAVE'}
              </FdButton>
            </Box>
          ) : (
            <FdButton
              variant="primary"
              size="small"
              onClick={() => setEditMode(true)}
            >
              Edit
            </FdButton>
          )}
        </Box>
      }
    >
      {editMode ? (
        <>
          <Controller
            control={control}
            name="websiteLink"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mt={1} mb={3}>
                <FdTextField
                  id="websiteLink"
                  label="Organisation Website Link"
                  placeholder="Type/Paste a Link"
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="linkedinLink"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mt={1} mb={3}>
                <FdTextField
                  id="linkedinLink"
                  label="Linkedin Link"
                  placeholder="Type/Paste a Link"
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="about"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mt={1} mb={3}>
                <FdTextField
                  id="about"
                  label="About"
                  fullWidth
                  error={error}
                  placeholder="Please provide a brief description of your organisation."
                  helperText={error && error.message}
                  multiline
                  rows={3}
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />

          <Controller
            control={control}
            name="orgRange"
            render={({ field, fieldState: { error } }) => (
              <Box mt={1} mb={3}>
                <FdSelect
                  id="orgRange"
                  label="How many people are in your organisation?"
                  options={Object.values(ORG_RANGE)}
                  placeholder="Select from the range"
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  defaultSelected={field?.value}
                  {...field}
                />
              </Box>
            )}
          />

          <Controller
            control={control}
            name="orgSector"
            render={({ field, fieldState: { error } }) => (
              <Box mt={1} mb={3}>
                <FdSelect
                  id="orgSector"
                  label="Which sector best describes your organisation?"
                  options={Object.values(ORG_SECTOR)?.sort()}
                  error={error}
                  placeholder="Select a sector from the list"
                  helperText={error && error.message}
                  fullWidth
                  defaultSelected={field?.value}
                  {...field}
                />
              </Box>
            )}
          />

          {orgSectorWatch === ORG_SECTOR.OTHER_ORG_SECTOR && (
            <Controller
              control={control}
              name="orgSectorOther"
              render={({ field, fieldState: { error } }) => (
                <Box mt={1} mb={3} ml={2}>
                  <FdTextField
                    id="orgSectorOther"
                    label="You selected other, please specify the sector for your organisation."
                    error={error}
                    fullWidth
                    placeholder="Type in the sector"
                    helperText={error && error.message}
                    {...field}
                  />
                </Box>
              )}
            />
          )}
        </>
      ) : (
        <>
          <FdTypography variant="subtitle1">
            Organisation Website Link
          </FdTypography>
          <Box display="flex">
            <FdTypography
              variant={getValues()?.websiteLink ? 'body1' : 'body2'}
              color="secondary"
            >
              {getValues()?.websiteLink || orgDefaultValue}
            </FdTypography>
            {getValues()?.websiteLink && (
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(getValues()?.websiteLink);
                }}
              >
                <ContentCopy color="action" />
              </Button>
            )}
          </Box>
          <Box my={3}>
            <FdTypography variant="subtitle1">Linkedin Link</FdTypography>
            <Box display="flex">
              <FdTypography
                variant={getValues()?.linkedinLink ? 'body1' : 'body2'}
                color="secondary"
              >
                {getValues()?.linkedinLink || orgDefaultValue}
              </FdTypography>
              {getValues()?.linkedinLink && (
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(getValues()?.linkedinLink);
                  }}
                >
                  <ContentCopy color="action" />
                </Button>
              )}
            </Box>
          </Box>
          <FdTypography variant="subtitle1">About</FdTypography>
          <FdTypography
            variant={getValues()?.about ? 'body1' : 'body2'}
            color="secondary"
          >
            {getValues()?.about || orgDefaultValue}
          </FdTypography>
          <Box my={3}>
            <FdTypography variant="subtitle1">
              How many people are in your organisation?
            </FdTypography>
            <FdTypography
              variant={getValues()?.orgRange ? 'body1' : 'body2'}
              color="secondary"
            >
              {getValues()?.orgRange || orgDefaultValue}
            </FdTypography>
          </Box>
          <FdTypography variant="subtitle1">
            Which sector best describes your organisation?
          </FdTypography>
          <FdTypography
            variant={getValues()?.orgSector ? 'body1' : 'body2'}
            color="secondary"
          >
            {getValues()?.orgSector || orgDefaultValue}
          </FdTypography>
          {orgSectorWatch === ORG_SECTOR.OTHER_ORG_SECTOR && (
            <Box mt={3} ml={2}>
              <FdTypography variant="subtitle1">
                You selected other, please specify the sector for your
                organisation.
              </FdTypography>
              <FdTypography
                variant={getValues()?.orgSectorOther ? 'body1' : 'body2'}
                color="secondary"
              >
                {getValues()?.orgSectorOther || orgDefaultValue}
              </FdTypography>
            </Box>
          )}
        </>
      )}
    </FdCard>
  );
};

export default OrgInformation;
