import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  FdTypography,
  FdCard,
  useSnapshot,
  globalStore,
  FdButton,
  FdIconsV5,
  FdModal,
  FdIconWithTooltip,
} from '@fifthdomain/fe-shared';
import {
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import { updateUserAffliation } from '../../graphql/mutations';

const AffiliationInfoCard = ({ refetchQueries, tempOrgName }) => {
  const globalSnap = useSnapshot(globalStore);
  const [disaffiliateUser, setDisaffiliateUser] = useState(undefined);
  const { LinkOff } = FdIconsV5;

  const [
    updateUserAffiliationMutation,
    { loading: updateUserAffiliationMutationLoading },
  ] = useMutation(gql(updateUserAffliation), {
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });

  return (
    <Box>
      <FdCard variant="outlined">
        <Box>
          <Box className="flex items-center">
            <FdTypography variant="h3">Organisation affiliation</FdTypography>
            <FdIconWithTooltip
              title={
                <Box>
                  The organisation you are affiliated to has access to your
                  skills data accumulated from all events on FifthDomain
                  platform, including those hosted by other organisations.{' '}
                  <br />
                  <br />
                  This allows your parent organisation to better understand your
                  skills and contributions across various cybersecurity
                  challenges.
                  <br />
                  <br />
                  With affiliation, you also have access to the Hall of Fame of
                  this organisation, which is an organisation wide leaderboard
                  of participants.
                </Box>
              }
            />
          </Box>
          <Box my={2}>
            <FdTypography variant="subtitle1">
              Affiliated Organisation
            </FdTypography>
            <FdTypography variant="body1" color="secondary">
              {globalSnap?.orgName}
            </FdTypography>
          </Box>
        </Box>
        <Box>
          <FdButton
            startIcon={<LinkOff />}
            style={{ backgroundColor: 'rgba(198, 40, 40, 1)' }}
            onClick={() =>
              setDisaffiliateUser({
                userId: globalSnap?.userId,
                email: globalSnap?.email,
                orgName: globalSnap?.orgName,
              })
            }
          >
            disaffiliate from organisation
          </FdButton>
        </Box>
      </FdCard>
      <FdModal
        size="md"
        title="Request to disaffiliate from this organisation?"
        description=""
        confirm="Disaffiliate"
        dismiss="CANCEL"
        open={disaffiliateUser}
        disableConfirm={updateUserAffiliationMutationLoading}
        confirmLoading={updateUserAffiliationMutationLoading}
        onConfirm={() => {
          updateUserAffiliationMutation({
            variables: {
              userId: disaffiliateUser?.userId,
              email: disaffiliateUser?.email,
              orgId: globalSnap?.orgId,
              affiliationAction: 'DISAFFILIATE',
            },
            onCompleted: (_data) => {
              successToastMessage(
                `You have disaffiliated with ${tempOrgName}.`,
              );
              setDisaffiliateUser(undefined);
              refetchQueries();
              globalStore.userType = 'PARTICIPANT';
              globalStore.hasManagerialPermissions = false;
              globalStore.isAffiliated = false;
              globalStore.mode = 'Participate';
            },
          });
        }}
        onDismiss={() => {
          setDisaffiliateUser(undefined);
          warningToastMessage('No changes made to affiliation status.');
        }}
      >
        <Box>
          <Box mt={1}>
            <FdTypography variant="body2">
              {`Are you sure you want to disaffiliate from ${globalSnap?.orgName}?
              By doing so, your status will change to a non-affiliated user
              within the organisation.`}
            </FdTypography>
            <Box mt={1}>
              <FdTypography variant="body2">
                Managers of this organisation will no longer be able to view
                your cumulative skills data for events hosted by other
                organisations.
              </FdTypography>
            </Box>
          </Box>
        </Box>
      </FdModal>
    </Box>
  );
};

AffiliationInfoCard.propTypes = {
  refetchQueries: PropTypes.func.isRequired,
  tempOrgName: PropTypes.string.isRequired,
};

export default AffiliationInfoCard;
