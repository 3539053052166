import React, { useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  AuthContext,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';

const AffiliatedRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        setUser(authUser);
        // add logged in user to global state
        globalStore.user = authUser;
      })
      .catch(() => {
        singleSpa.navigateToUrl('/');
      });
  }, []);

  if (globalSnap.loading || !globalSnap.orgId) {
    <FdLoadingSpinner />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && globalSnap.isAffiliated) {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

AffiliatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AffiliatedRoute;
