import React, { useState, useContext } from 'react';
import { Box, makeStyles, Container, Grid } from '@material-ui/core';
import {
  FdCard,
  FdButton,
  FdTypography as Typography,
  AuthContext,
  FdModal,
  FdTextField,
  InformationBox,
  passwordRuleMessages,
} from '@fifthdomain/fe-shared';

import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
  form: {
    height: '230px',
    color: theme.palette.text.primary,
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const PasswordChange = () => {
  const {
    user,
    loading,
    handleChangePassword,
    error: authError,
    success,
  } = useContext(AuthContext);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const classes = useStyles();
  const [showMessage, setShowMessage] = useState(false);

  const passwordValidationMessages = ({ value: password }) => {
    return passwordRuleMessages(password);
  };

  const initialValues = {
    passwordNew: '',
    passwordOld: '',
  };

  const validationSchema = Yup.object().shape({
    passwordNew: Yup.string()
      .required('This field is required')
      .test(
        'passwordRules',
        passwordValidationMessages,
        (value) => value && passwordRuleMessages(value) === undefined,
      ),
    passwordOld: Yup.string().required('This field is required'),
  });

  const { control, getValues, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    const { passwordOld, passwordNew } = getValues();
    await handleChangePassword(user, passwordOld, passwordNew);
    setShowMessage(true);
  };

  const handleHideChangePassword = () => {
    setShowChangePassword(false);
    setShowMessage(false);
  };

  return (
    <>
      <FdCard variant="outlined">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3">Password</Typography>
          <Box display="flex" justifyContent="space-between">
            <FdButton
              variant="primary"
              size="small"
              onClick={() => setShowChangePassword(true)}
            >
              EDIT
            </FdButton>
          </Box>
        </Box>
      </FdCard>
      {showChangePassword && (
        <FdModal
          title="Change Password"
          confirm={loading ? 'Saving...' : 'Save'}
          dismiss={success ? 'Close' : 'Cancel'}
          open
          onConfirm={() => {
            handleSubmit(() => onSubmit())();
          }}
          onDismiss={handleHideChangePassword}
        >
          <Container component="main" maxWidth="sm">
            {authError && showMessage && (
              <InformationBox variant="error" status="" message={authError} />
            )}
            {success && showMessage && (
              <InformationBox
                status=""
                variant="success"
                message="Password changed successfully"
              />
            )}
          </Container>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="passwordOld"
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <FdTextField
                      id="passwordOld"
                      label="Current Password"
                      autoComplete="password"
                      type="password"
                      helperText={error ? error.message : null}
                      error={!!error}
                      required
                      fullWidth
                      inputRef={ref}
                      {...fieldProps}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="passwordNew"
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <FdTextField
                      id="passwordNew"
                      label="New Password"
                      autoComplete="new-password"
                      required
                      fullWidth
                      name="passwordNew"
                      type="password"
                      helperText={error ? error.message : null}
                      error={!!error}
                      inputRef={ref}
                      {...fieldProps}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </FdModal>
      )}
    </>
  );
};

export default PasswordChange;
