import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import {
  FdTable,
  FdTooltip,
  FdTypography,
  FdButton,
} from '@fifthdomain/fe-shared';
import { productStyle } from '../../shared/utils/layout';
import CardProgressBar from './CardProgressBar';

const ParticipantEventTable = ({ rows, onEnterClick }) => {
  const theme = useTheme();
  const actions = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      CustomElement: (row) => {
        const { eventAction } = row?.rowData || {};
        return (
          <FdButton
            variant="primary"
            size="small"
            onClick={() => onEnterClick(row?.rowData)}
          >
            {eventAction}
          </FdButton>
        );
      },
      width: 150,
    },
  ];

  return (
    <Box height={480}>
      <FdTable
        toolbarSettings={{
          searchBox: true,
        }}
        actions={actions}
        rows={rows}
        columns={[
          {
            field: 'eventName',
            flex: 2,
            headerName: 'Event Name',
            valueGetter: (params) => params.row.title,
            renderCell: (params) => {
              const { row } = params;
              return (
                <Box display="flex" minWidth="391px">
                  <Box
                    display="inline-block"
                    ml={1}
                    style={{
                      ...productStyle(row.contentType?.toUpperCase(), theme),
                      paddingTop: 0,
                    }}
                  >
                    {row.icon}
                  </Box>
                  <Box className="flex flex-col justify-around ml-3">
                    <Box width={391}>
                      <FdTooltip
                        title={row.title?.length > 40 ? row.title : ''}
                      >
                        <span>
                          <FdTypography
                            variant="body2"
                            fontWeight="medium"
                            className="overflow-hidden whitespace-nowrap overflow-ellipsis mb-1"
                          >
                            {row.title}
                          </FdTypography>
                        </span>
                      </FdTooltip>
                    </Box>
                    <Box className="flex items-center">
                      {row.contentType === 'course' &&
                        row.courseModulesCount > 0 && (
                          <Box
                            display="inline-block"
                            ml={1}
                            style={{
                              ...productStyle('COURSE', theme),
                              padding: '1px 4px',
                            }}
                          >
                            <FdTypography variant="captiontext2">
                              {`${row.courseModulesCount} modules`}
                            </FdTypography>
                          </Box>
                        )}
                      {row.contentType === 'assessment' && (
                        <Box display="flex" ml={1}>
                          <span
                            style={{
                              ...productStyle('ASSESSMENT', theme),
                              padding: '1px 4px',
                            }}
                          >
                            <FdTypography variant="captiontext2">
                              {`Duration: ${row.duration}`}
                            </FdTypography>
                          </span>
                        </Box>
                      )}
                      {row.contentType === 'competition' && (
                        <Box display="flex" ml={1}>
                          <span
                            style={{
                              ...productStyle('COMPETITION', theme),
                              padding: '1px 4px',
                            }}
                          >
                            <FdTypography variant="captiontext2">
                              {`${row.type} Competition`}
                            </FdTypography>
                          </span>
                        </Box>
                      )}
                      {row.contentType === 'training' && (
                        <Box className="flex gap-x-1">
                          <Box display="flex">
                            <span
                              style={{
                                ...productStyle('TRAINING', theme),
                                padding: '1px 4px',
                                marginRight: '8px',
                              }}
                            >
                              <FdTypography variant="captiontext2">
                                Training
                              </FdTypography>
                            </span>
                          </Box>
                          <Box display="flex">
                            <span
                              style={{
                                ...productStyle('TRAINING', theme),
                                padding: '1px 4px',
                                marginRight: '8px',
                              }}
                            >
                              <FdTypography variant="captiontext2">
                                {`${row?.tasksCount} Challenges`}
                              </FdTypography>
                            </span>
                          </Box>
                        </Box>
                      )}
                      {row.eventAction === 'Continue' && (
                        <Box display="flex" ml={1}>
                          <CardProgressBar content={row} linearProgress />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            },
          },
        ]}
      />
    </Box>
  );
};
ParticipantEventTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onEnterClick: PropTypes.func.isRequired,
};

export default ParticipantEventTable;
