import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isWithinInterval, subMonths, format } from 'date-fns';
import { gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import {
  useQueryRecursive,
  FdSkeleton,
  FdTypography,
} from '@fifthdomain/fe-shared';
import HeatMapCell from './HeatMapCell';
import {
  listSkills,
  listTaskAttemptAggregatesByUserId,
} from '../../../graphql/queries';
import { getArrayByLength } from '../../../shared/utils/objectUtils';
import { PROFICIENCY, PROFICIENCY_LEVELS } from '../../../constants';
import { getLabelColor } from '../../../shared/utils/layout';

const SPECIALTY_SORT = {
  Intelligence: { order: 1, paddingTop: 26 },
  Penetration: { order: 2, paddingTop: 20 },
  Protection: { order: 3, paddingTop: 2 },
  Detection: { order: 4, paddingTop: 2 },
  Engineering: { order: 5, paddingTop: 10 },
  Investigation: { order: 6, paddingTop: 2 },
};
const degradationRate = {
  1: 2,
  2: 3,
  3: 5,
  4: 8,
  5: 13,
};

const CyberBrain = ({ userId, timeLine }) => {
  const { data: listSkillsData, loading: listSkillsLoading } =
    useQueryRecursive(gql(listSkills));

  const {
    data: listTaskAttemptAggregatesByUserIdData,
    loading: listTaskAttemptAggregatesByUserIdLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByUserId), {
    variables: {
      userId,
      limit: 1000,
    },
    skip: !userId,
  });

  const specialties = _(listSkillsData?.listSkills?.items || [])
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items,
    }))
    .value();

  const difficultyLevels = getArrayByLength(5, (__, i) => i + 1);
  const listTaskAttemptAggregates =
    listTaskAttemptAggregatesByUserIdData?.listTaskAttemptAggregatesByUserId
      ?.items || [];
  const attemptsData = listTaskAttemptAggregates?.filter(
    (la) => la?.task?.skills?.items?.length > 0,
  );
  const attemptsDataUnique = _.uniqBy(attemptsData, 'taskId');
  const attemptsDataTimeLineBased = timeLine
    ? attemptsDataUnique?.filter((a) =>
        isWithinInterval(new Date(a?.updatedAt), {
          start: new Date(timeLine?.startDate),
          end: new Date(timeLine?.endDate),
        }),
      )
    : attemptsDataUnique;

  const onlySolves = attemptsDataTimeLineBased?.filter(
    (a) => a?.status === 'COMPLETED',
  );
  const solvesOrEfforts = onlySolves;

  // logic for level solves/effort
  // Ln = All solves/effort in Sum (Ln.. + L5)
  const getLevelPoints = (_level, _attempts) => {
    const levelEndDate2 = new Date();
    const levelStartDate2 = subMonths(levelEndDate2, degradationRate[_level]);

    return (
      _attempts?.filter(
        (a) =>
          a?.task?.difficulty === _level &&
          isWithinInterval(new Date(a?.updatedAt), {
            start: levelStartDate2,
            end: levelEndDate2,
          }),
      )?.length || 0
    );
  };

  const getDegradationScore = (_level, _attempts) => {
    let finalScore = 0;
    for (let index = _level; index <= 5; index += 1) {
      const levelEndDate = new Date();
      const levelStartDate = subMonths(levelEndDate, degradationRate[index]);
      const scoreInTimeFrame =
        _attempts?.filter(
          (a) =>
            a?.task?.difficulty === index &&
            isWithinInterval(new Date(a?.updatedAt), {
              start: levelStartDate,
              end: levelEndDate,
            }),
        )?.length || 0;
      finalScore += scoreInTimeFrame;
    }
    return finalScore > 0 ? finalScore : 0;
  };

  const specialtiesFormatted =
    specialties
      ?.sort(
        (a, b) =>
          (SPECIALTY_SORT[a.specialtyName]?.order ?? 0) -
          (SPECIALTY_SORT[b.specialtyName]?.order ?? 0),
      )
      ?.map((specialty, idx) => {
        const flip = (idx + 1) % 2 === 1;
        const showHeader = idx + 1 < 3;
        const levels = flip
          ? [...difficultyLevels].reverse()
          : difficultyLevels;

        const dataSorted = specialty?.items?.map((skill) => {
          const attempts =
            solvesOrEfforts?.filter((a) =>
              a?.task.skills.items?.some((s) =>
                s?.skill?.alias?.includes(skill?.alias),
              ),
            ) || [];
          const lastSolveDate = _.maxBy(
            attempts,
            (item) => new Date(item.updatedAt),
          )?.updatedAt;
          const lastSolveDateFormatted = lastSolveDate
            ? format(new Date(lastSolveDate), 'dd/MM/yyyy')
            : 'N/A';

          return {
            id: skill?.alias,
            data: [
              ...(levels?.map((l) => {
                const levelPoints = getLevelPoints(l, attempts) || undefined;
                return {
                  x: l,
                  y: getDegradationScore(l, attempts) || 0,
                  yLabel: levelPoints,
                  yLabelName: `${skill?.name} (${skill?.alias})`,
                  yType: 'solves',
                  yLevelPoints:
                    attempts?.filter((a) => a?.task?.difficulty === l)
                      ?.length || 0,
                  yLabelLastSolve: levelPoints ? lastSolveDateFormatted : 'N/A',
                };
              }) || []),
            ],
          };
        });
        return { ...specialty, flip, showHeader, levels, dataSorted };
      }) || [];

  const topSpecialties = specialtiesFormatted?.map((s) => {
    const proficiencyLevelOfSolves = s?.dataSorted?.reduce(
      (acc, i) =>
        // eslint-disable-next-line no-unsafe-optional-chaining
        acc + i?.data?.reduce((dAcc, d) => dAcc + (d.y !== 0 ? 1 : 0), 0),
      0,
    );
    const skillsCovered = s?.dataSorted?.reduce(
      // eslint-disable-next-line no-unsafe-optional-chaining
      (acc, i) => acc + i?.data?.reduce((dAcc, d) => dAcc + (d.y ? 1 : 0), 0),
      0,
    );
    return {
      specialty: s?.specialtyName,
      score: (1 / 3) * proficiencyLevelOfSolves + (2 / 3) * skillsCovered,
      dataSorted: s?.dataSorted,
    };
  });

  const topSpecialty =
    _.orderBy(
      topSpecialties?.filter((ts) => ts?.score > 0),
      ['score', 'specialty'],
      ['desc', 'asc'],
    )?.[0]?.specialty || '';

  const allTopSkills = topSpecialties
    ?.map((ts) =>
      ts?.dataSorted?.map((d) => ({
        id: d?.id,
        // eslint-disable-next-line no-unsafe-optional-chaining
        score: d?.data?.reduce((acc, i) => acc + i?.y, 0),
        topRow: _.maxBy(
          d?.data?.filter((ds) => ds?.y > 0),
          'x',
        ),
      })),
    )
    ?.flat()
    ?.filter((x) => x?.score > 0);

  const top3Skills =
    _.orderBy(allTopSkills, ['score', 'id'], ['desc', 'asc']).slice(0, 3) || [];

  const oddNumberSpecialties = (specialtiesFormatted?.length ?? 0) % 2 !== 0;
  const specialtiesCount = specialtiesFormatted?.length || 0;

  return (
    <FdSkeleton
      loading={listSkillsLoading || listTaskAttemptAggregatesByUserIdLoading}
      height={665}
    >
      <Box
        id="cyber-brain"
        className="flex flex-col items-center justify-center"
        width={545}
      >
        <Box className="grid grid-cols-2 gap-x-3" height="100%">
          {specialtiesFormatted?.map((specialty, i) => (
            <Box
              style={{
                paddingRight: specialty?.flip && '1rem',
                position: 'relative',
                paddingBottom: 0,
              }}
            >
              <>
                <HeatMapCell
                  data={specialty?.dataSorted}
                  label={specialty?.specialtyName}
                  flip={specialty?.flip}
                  showHeader={specialty?.showHeader}
                  colors={{
                    type: 'sequential',
                    scheme: 'purples',
                    minValue: 0,
                    maxValue: 50,
                  }}
                  margin={{
                    top: SPECIALTY_SORT[specialty?.specialtyName]?.paddingTop,
                    right: specialty?.flip ? 2 : 47,
                    bottom: 0,
                    left: specialty?.flip ? 36 : 2,
                  }}
                />
                {specialty?.flip && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: [0, 1].includes(i) ? '35px' : 0,
                      right: 0,
                      bottom: oddNumberSpecialties
                        ? specialtiesCount - 1
                        : specialtiesCount - 2 === i
                        ? '24px'
                        : 0,
                      borderRight: '1px dashed #63A4FF',
                    }}
                  />
                )}
              </>
            </Box>
          ))}
        </Box>
        <Box className="flex items-start gap-x-4 mt-1">
          <Box>
            <FdTypography
              variant="captiontext1"
              style={{ fontWeight: 700, marginBottom: '1rem' }}
            >
              Most Aligned Professional Specialty
            </FdTypography>
            {topSpecialty ? (
              <Box>
                <FdTypography variant="captiontext2" color="secondary">
                  You currently have most alignment with the following
                  Professional Specialty:
                </FdTypography>
                <Box mt={1}>
                  <Box className="mb-3">
                    <FdTypography variant="captiontext2">
                      <span
                        style={{
                          padding: '4px 8px',
                          borderRadius: '4px',
                          backgroundColor: 'rgba(227, 230, 236, 1)',
                          color: 'rgba(0, 0, 0, 1)',
                          fontWeight: 600,
                        }}
                      >
                        {topSpecialty}
                      </span>
                    </FdTypography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box>
                <FdTypography variant="captiontext2" color="secondary">
                  As you embark on solving challenges during
                  competitions/assessments, the Professional Specialty most
                  closely aligned with your skills, will dynamically update.
                </FdTypography>
              </Box>
            )}
          </Box>
          <Box>
            <FdTypography
              variant="captiontext1"
              style={{ fontWeight: 700, marginBottom: '1rem' }}
            >
              Top Skills Demonstrated
            </FdTypography>
            {top3Skills?.length > 0 ? (
              <Box>
                <FdTypography variant="captiontext2" color="secondary">
                  You have demonstrated strong abilities in the following
                  skills:
                </FdTypography>
                <Box mt={1}>
                  {top3Skills?.map((ts) => {
                    const { yLabelName, x } = ts?.topRow || {};
                    const proficiency = PROFICIENCY_LEVELS[x];
                    const proficiencyColor = PROFICIENCY[proficiency]?.color;
                    return (
                      <Box my={2}>
                        <FdTypography
                          variant="captiontext1"
                          style={{ fontWeight: 600 }}
                        >
                          {yLabelName}
                        </FdTypography>
                        <Box className="flex my-2">
                          <FdTypography variant="captiontext2">
                            <span
                              style={{
                                padding: '3px 8px',
                                borderRadius: '4px',
                                backgroundColor: proficiencyColor,
                                color: getLabelColor(proficiencyColor),
                                fontWeight: 600,
                              }}
                            >
                              {proficiency}
                            </span>
                          </FdTypography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ) : (
              <Box>
                <FdTypography variant="captiontext2" color="secondary">
                  As you engage in solving challenges within
                  competitions/assessments, your top demonstrated skills will
                  dynamically update
                </FdTypography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </FdSkeleton>
  );
};

CyberBrain.defaultProps = {
  userId: undefined,
  timeLine: undefined,
};

CyberBrain.propTypes = {
  userId: PropTypes.string,
  timeLine: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
};

export default CyberBrain;
