import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useAsyncEffect from 'use-async-effect';
import { Box } from '@material-ui/core';
import {
  FdSkeleton,
  getAvatarColor,
  getAvatarName,
  FdIcons,
} from '@fifthdomain/fe-shared';
import { getPreSignedUrl } from '../../shared/utils/getImageFromS3';

const { Person } = FdIcons;

const UserAvatar = ({ size, user, anonymous }) => {
  const [imageUrl, setImageUrl] = useState(undefined);
  const isLargeSize = size === 'large';
  const dimension = isLargeSize ? '84px' : '24px';

  useAsyncEffect(async () => {
    const fileUrlS3 = await getPreSignedUrl(`/user-avatars/${user?.key}`);
    setImageUrl(fileUrlS3);
  });
  // eslint-disable-next-line react/no-unstable-nested-components
  const NoImageAvatar = () => {
    const name = getAvatarName(user?.name);
    const avatarName = name === '-' ? '' : name;
    const avatarColor =
      name === '-' ? 'rgba(223, 223, 223, 1)' : getAvatarColor(user?.name);
    return (
      <Box
        height={dimension}
        width={dimension}
        className="relative inline-flex items-center justify-center rounded-full overflow-hidden text-white"
        style={{
          backgroundColor: anonymous ? 'lightgrey' : avatarColor,
          fontSize: isLargeSize ? '30px' : '12px',
          lineHeight: 1,
          marginTop: '4px',
        }}
      >
        <span>{anonymous ? <Person /> : avatarName}</span>
      </Box>
    );
  };

  if (anonymous) {
    <NoImageAvatar />;
  }

  return user?.key ? (
    <FdSkeleton
      loading={!imageUrl}
      variant="circle"
      height={dimension}
      width={dimension}
    >
      <img
        src={imageUrl}
        alt="avatar"
        style={{ width: dimension, height: dimension }}
        className="rounded-full object-cover"
      />
    </FdSkeleton>
  ) : (
    <NoImageAvatar />
  );
};

UserAvatar.defaultProps = {
  anonymous: false,
};

UserAvatar.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.shape({
      imageKey: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  size: PropTypes.oneOf(['large', 'small']).isRequired,
  anonymous: PropTypes.bool,
};

export default UserAvatar;
