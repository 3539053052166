import _ from 'lodash';
import { EMAIL_REGEX } from '../../shared/utils/emailUtil';
import Yup from '../Common';
import { getCommaSeparated } from '../../shared/utils/stringUtils';

export const initialValues = {
  id: undefined,
  name: '',
  avatar: undefined,
  error: false,
  emails: [],
  key: '',
  members: [],
  events: [],
  private: false,
};

export const validationSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string()
    .required('Please enter a team name.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please enter a valid team name.',
      (value) => value && value.length === value.trim().length,
    )
    .max(25, 'Team name must be 25 characters or less.'),
  avatar: Yup.mixed(),
  key: Yup.string(),
  private: Yup.bool(),
  error: Yup.bool().oneOf([false]),
  emails: Yup.array()
    .of(Yup.string().matches(EMAIL_REGEX, 'Please enter a valid email.'))
    .min(1, 'Please enter an email before sending an invite.')
    .max(
      50,
      'You cannot invite over 50 users in one invitation instance. Please reduce the number of emails and try again.',
    )
    .test({
      name: 'unique-email',
      // eslint-disable-next-line object-shorthand, func-names
      test: function (values) {
        const allEventEmails = [
          ...new Set(
            this.parent.events
              .map((e) =>
                e.eventTeams?.items
                  ?.filter((t) => t.teamId !== this.parent.id)
                  ?.map((tm) =>
                    tm?.team?.members?.items?.map((tme) => tme?.email),
                  )
                  ?.flat(),
              )
              ?.flat(),
          ),
        ];
        const commonEmails = _.intersection(allEventEmails, values);
        // if there is a clash of common emails between newly added members
        // and members who exists in events which are part of other teams show message
        return commonEmails?.length > 0
          ? this.createError({
              message: `You cannot invite ${getCommaSeparated(
                commonEmails,
              )} as they are already part of a competition that your team is currently competing in. Please remove ${getCommaSeparated(
                commonEmails,
              )} and try again.`,
              path: 'emails',
            })
          : true;
      },
    }),
  members: Yup.array().of(
    Yup.object().shape({
      alias: Yup.string(),
      email: Yup.string(),
      role: Yup.string(),
      status: Yup.string(),
    }),
  ),
  events: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      name: Yup.string(),
      status: Yup.string(),
      timeStamp: Yup.string(),
      eventTeams: Yup.object().shape({
        items: Yup.array().of(Yup.object().shape({})),
      }),
    }),
  ),
});
