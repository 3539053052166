import React from 'react';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import { isWithinInterval, isBefore } from 'date-fns';
import {
  getCategoryDescription,
  getDifficultyLabel,
} from '@fifthdomain/fe-shared';

export const getTasksTableData = (tasksData) =>
  _.chain(tasksData)
    .map(
      ({
        node: { id, name, category, difficulty, solveTime, recommendedPoints },
      }) => ({
        id,
        name,
        category: getCategoryDescription(category),
        difficulty: getDifficultyLabel(difficulty),
        solveTime,
        recommendedPoints,
      }),
    )
    .value();

const getTasksEdges = (allTasks, selectedTasks) =>
  allTasks?.filter((edge) => selectedTasks?.includes(edge?.id));

const getUniqueTasksEdges = (allTasks, selectedTasks) =>
  _.uniqBy(getTasksEdges(allTasks, selectedTasks), 'id');

export const getCategoryGraphData = (allTasks, selectedTasks) => {
  const edges = getUniqueTasksEdges(allTasks, selectedTasks);
  const categoryDifficultyMap = edges?.map((edge) => ({
    category: getCategoryDescription(edge?.category),
    difficulty: edge?.difficulty,
  }));

  const graphData = [
    ...new Set(categoryDifficultyMap?.map((o) => o.category)),
  ].map((c) => {
    const tasksCategory = categoryDifficultyMap.filter((d) => d.category === c);
    return {
      category: `${c} (${tasksCategory.length})`,
      Easy: tasksCategory.filter((d) => d.difficulty === 'Easy').length,
      Medium: tasksCategory.filter((d) => d.difficulty === 'Medium').length,
      Hard: tasksCategory.filter((d) => d.difficulty === 'Hard').length,
    };
  });
  return graphData;
};

export const getTotal = (edges, fieldName) =>
  edges?.reduce((accumulator, edge) => {
    const fieldValue = edge?.[fieldName];
    return fieldValue ? accumulator + fieldValue : accumulator;
  }, 0);

export const getLabBasedTasks = (allTasks, selectedTasks) => {
  return allTasks
    .filter((task) => selectedTasks.includes(task.id))
    .filter((t) => t.type === 'LAB');
};

export const getTasksSummaryData = (allTasks, selectedTasks) => {
  const edges = getUniqueTasksEdges(allTasks, selectedTasks);
  return [
    {
      value: selectedTasks?.length,
      description: (
        <Box display="flex" alignItems="center">
          Total Challenges
        </Box>
      ),
    },
    {
      value: getLabBasedTasks(allTasks, selectedTasks).length,
      description: (
        <Box display="flex" alignItems="center">
          Lab Based Challenges
        </Box>
      ),
    },
    {
      value: getTotal(edges, 'estimatedSolveTime'),
      description: 'Total Est. Time to Solve (mins)',
    },
    {
      value: [...new Set(edges?.map((e) => e.category))].length,
      description: 'Categories',
    },
    {
      value: getTotal(edges, 'recommendedPoints'),
      description: 'Total Points',
    },
  ];
};

export const getAssessmentStatus = (
  startDateTime,
  endDateTime,
  currentTime,
) => {
  let status = 'Not Started';
  if (!startDateTime && !endDateTime) {
    status = 'Not Started';
  } else if (
    startDateTime &&
    endDateTime &&
    isBefore(new Date(endDateTime), new Date(currentTime))
  ) {
    status = 'Ended';
  } else if (
    isBefore(new Date(startDateTime), new Date(currentTime)) ||
    (startDateTime &&
      endDateTime &&
      isWithinInterval(new Date(currentTime), {
        start: new Date(startDateTime),
        end: new Date(endDateTime),
      }))
  ) {
    status = 'In Progress';
  }

  return status;
};

export const getDeletingTasks = (existingTasks, newTasks) => {
  return existingTasks.reduce((acc, curr) => {
    if (!newTasks.find((t) => t.taskId === curr.taskId)) {
      acc.push(curr);
    }

    return acc;
  }, []);
};

export const getNewTasks = (existingTasks, newTasks) => {
  return newTasks.reduce((acc, curr) => {
    if (!existingTasks.find((t) => t.taskId === curr.taskId)) {
      acc.push(curr);
    }

    return acc;
  }, []);
};

export const DIFFICULTY_GRAPH_COLOR = {
  Easy: '#4CAF50',
  Medium: '#FF9800',
  Hard: '#F44336',
};

export const getCourseProgressStatus = ({
  partsProgress,
  courseModulePartIds,
}) => {
  // only consider data with a valid modulePart
  const partsFinished =
    partsProgress?.filter((p) => p.status === 'FINISHED').length || 0;
  let status = 'Not Started';
  const courseProgress =
    courseModulePartIds && courseModulePartIds.length > 0
      ? Math.round((partsFinished / courseModulePartIds.length) * 100)
      : 0;

  if (courseProgress > 0 && courseProgress < 100) {
    status = 'In Progress';
  }
  if (courseProgress >= 100) {
    status = 'Completed';
  }
  return { status, courseProgress };
};

export const getAchievedPoints = (competitionChallenges, userAssessmentId) => {
  const completedChallenges = competitionChallenges
    ?.map((_taskItem) =>
      _taskItem?.task?.attempts?.items?.filter(
        (_ta) => _ta?.taskAttemptUserAssessmentId === userAssessmentId,
      ),
    )
    .flat();

  const achievedPoints = getTotal(
    completedChallenges?.map((c) => ({
      ...c,
      points: c.task.recommendedPoints,
    })),
    'points',
  );
  return achievedPoints;
};

export const getCompletedAssessmentTasksCount = (
  assessmentTasks,
  userAssessmentId,
) => {
  const completedTasks = assessmentTasks
    ?.map((_taskItem) =>
      _taskItem.task.attempts.items.filter(
        (_ta) => _ta.taskAttemptUserAssessmentId === userAssessmentId,
      ),
    )
    .flat();
  return completedTasks?.length;
};

export const TASK_CATEGORY = {
  STEGANOGRAPHY: {
    color: '#F06292',
    secondaryColor: '#F8BBD0',
    label: 'Steganography',
  },
  PHYSICAL: {
    color: '#2196F3',
    secondaryColor: '#90CAF9',
    label: 'Physical',
  },
  HARDWARE: {
    color: '#81D4FA',
    secondaryColor: '#E1F5FE',
    label: 'Hardware',
  },
  ICSSCADA: {
    color: '#00BCD4',
    secondaryColor: '#B2EBF2',
    label: 'ICS/SCADA',
  },
  IOT: {
    color: '#26A69A',
    secondaryColor: '#B2DFDB',
    label: 'IOT',
  },
  WIRELESS: {
    color: '#2E7D32',
    secondaryColor: '#4CAF50',
    label: 'Wireless',
  },
  TRIVIA: {
    color: '#8BC34A',
    secondaryColor: '#C5E1A5',
    label: 'Trivia',
  },
  DFIR: {
    color: '#AB47BC',
    secondaryColor: '#CE93D8',
    label: 'DFIR',
  },
  FORENSICS: {
    color: '#F06292',
    secondaryColor: '#F8BBD0',
    label: 'Forensics',
  },
  MISC: {
    color: '#D4E157',
    secondaryColor: '#F0F4C3',
    label: 'Miscellaneous',
  },
  REVENG: {
    color: '#FB8C00',
    secondaryColor: '#FFCC80',
    label: 'Reverse Engineering',
  },
  EXPLOITATION: {
    color: '#5D4037',
    secondaryColor: '#A1887F',
    label: 'Exploitation',
  },
  SCRIPTING: {
    color: '#FF5722',
    secondaryColor: '#FF8A65',
    label: 'Scripting',
  },
  PWN: {
    color: '#00897B',
    secondaryColor: '#80CBC4',
    label: 'Pwn',
  },
  BOOTTOROOT: {
    color: '#FDD835',
    secondaryColor: '#FFF9C4',
    label: 'Boot2Root',
  },
  ANALYST: {
    color: '#512DA8',
    secondaryColor: '#9575CD',
    label: 'Analyst',
  },
  ANALYSIS: {
    color: '#512DA8',
    secondaryColor: '#9575CD',
    label: 'Analysis',
  },
  CRYPTOGRAPHY: {
    color: '#FFB300',
    secondaryColor: '#FFECB3',
    label: 'Cryptography',
  },
  OSINT: {
    color: '#5C6BC0',
    secondaryColor: '#9FA8DA',
    label: 'OSINT',
  },
  default: {
    color: '#00BCD4',
    secondaryColor: '#B2EBF2',
    label: 'Other',
  },
};

export const TASK_DIFFICULTY_COLOR = {
  Easy: '#44D47F',
  Medium: '#FFAD33',
  Hard: '#F6695E',
};

export const TASK_TYPE_COLOR = {
  Lab: 'rgba(107, 107, 242, 1)',
  Container: '#63A4FF',
  Static: 'rgba(158, 173, 255, 1)',
};

export const getReleasedTemplatesChallenges = (releasedTemplates) => {
  const challenges = releasedTemplates
    ?.map((template) => template.tasks.items?.map((task) => task.task))
    .flat();
  const uniqueChallenges = _.uniqBy(challenges, 'id');
  return uniqueChallenges;
};
