import React, { useState } from 'react';
import { Box, makeStyles, Grid, Button, useTheme } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import singleSpa from 'single-spa';
import _ from 'lodash';
import {
  BasePage,
  FdTypography,
  FdCard,
  FdIconsV5,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTab,
  FdSelect,
  FdButton,
  FdModal,
  FdDelayed,
  FdSkeleton,
  Authorization,
  PERMISSIONS_AFFILIATED,
} from '@fifthdomain/fe-shared';
import {
  listAssessmentsByOrg,
  listCoursesByOrgId,
  getSystemTime,
  listTasksByOrgId,
  listSharedAssessmentBySharedOrgId,
  listTaskOrgs,
  queryTemplatesByStatus,
} from '../graphql/queries';
import { productStyle } from '../shared/utils/layout';
import {
  getAssessmentStatus,
  getReleasedTemplatesChallenges,
} from '../shared/utils/taskUtils';
import {
  getDateTimeZoneFormatted,
  sortedByCreatedDate,
} from '../shared/utils/dateUtils';
import { getCourseStatus } from '../shared/utils/courseStatus';
import EventTable from '../components/Contents/EventTable';
import Summary from '../components/Contents/Summary';
import CompositionGraphs from '../components/Composition/CompositionGraphs';
import OrgUsers from '../components/UserInfo/OrgUsers';
import { ORG_PRODUCTS } from '../constants';
import NoContentImage from '../shared/images/no-content-data.svg';
import AffiliationTag from '../components/Affiliated/AffiliationTag';
import AffiliationLinkStatusModal from '../components/Affiliated/LandingMessageModals/AffiliationLinkStatusModal';
import SquadTag from '../components/Affiliated/SquadTag';

const useStyles = makeStyles(() => ({
  styledSelect: {
    '& [class*="MuiOutlinedInput"]': {
      padding: 0,
    },
    marginTop: '-10px',
  },
}));

const AdminLandingPage = () => {
  const classes = useStyles();
  const globalSnap = useSnapshot(globalStore);
  const [openModal, setOpenModal] = useState(undefined);
  const [selectedTemplateOption, setSelectedTemplateOption] = useState(null);
  const [createEventType, setCreateEventType] = useState(undefined);
  const theme = useTheme();
  const {
    Assignment,
    EmojiEvents,
    WbIncandescent,
    ContentCopy,
    Archive,
    Ballot,
    Create,
  } = FdIconsV5;
  // Event section only if I have manage_events or view insights permission.
  const hasManageEvents =
    Authorization.canManageEvents(globalSnap?.permissions) ||
    Authorization.canViewInsights(globalSnap?.permissions);
  // user section only if I have manage_users permission.
  const hasManageUser = Authorization.canManageUsers(globalSnap?.permissions);
  const hasManageContent = Authorization.hasPermission(
    globalSnap?.permissions,
    [
      PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
      PERMISSIONS_AFFILIATED.CREATE,
      PERMISSIONS_AFFILIATED.REVIEW_CONTENT,
    ],
  );

  const canCreateAndReview =
    Authorization.canCreate(globalSnap?.permissions) &&
    Authorization.canReviewContent(globalSnap?.permissions);

  const pricingTier = globalSnap.orgPricingTier;
  const orgProducts = globalSnap?.orgProducts;

  const { data: serverTime } = useQuery(gql(getSystemTime), {
    fetchPolicy: 'network-only',
  });

  const { data: listTasksByOrgIdData, loading: listTasksByOrgIdLoading } =
    useQueryRecursive(gql(listTasksByOrgId), {
      variables: {
        orgId: globalSnap.orgId,
        limit: 1000,
      },
      skip: !globalSnap.orgId,
    });

  const { data: allTasksAvailableToOrg, loading: allTasksAvailableLoading } =
    useQueryRecursive(gql(listTaskOrgs), {
      variables: {
        filter: {
          orgId: { eq: globalSnap.orgId },
        },
        limit: 1000,
      },
      fetchPolicy: 'cache-and-network',
      skip: !globalSnap.orgId,
    });

  const {
    data: listCoursesByOrgIdData,
    loading: listCoursesByOrgIdLoading,
    refetch: refetchCourses,
  } = useQueryRecursive(gql(listCoursesByOrgId), {
    variables: {
      orgId: globalSnap.orgId,
      filter: {
        status: {
          ne: 'DELETED',
        },
      },
    },
    skip: globalSnap.userType === 'PARTICIPANT' || !hasManageEvents,
  });

  const {
    data: listSharedAssessmentsByOrgData,
    refetch: refetchSharedAssessmentData,
  } = useQueryRecursive(gql(listSharedAssessmentBySharedOrgId), {
    variables: {
      sharedOrgId: globalSnap.orgId,
    },
    skip: !globalSnap.orgId || !hasManageEvents,
  });

  const {
    data: listAssessmentsByOrgData,
    loading: listAssessmentsByOrgLoading,
    refetch: refetchAssessmentData,
  } = useQueryRecursive(gql(listAssessmentsByOrg), {
    variables: {
      orgId: globalSnap.orgId,
    },
    onCompleted: async () => {
      await refetchSharedAssessmentData();
    },
    skip: !globalSnap.orgId || !hasManageEvents,
  });

  const { data: releasedTemplates, loading: templatesLoading } =
    useQueryRecursive(gql(queryTemplatesByStatus), {
      variables: {
        status: 'RELEASED',
      },
    });

  const releasedTemplatesChallenges =
    globalSnap.orgPricingTier === 'STARTER'
      ? []
      : getReleasedTemplatesChallenges(
          releasedTemplates?.queryTemplatesByStatus?.items,
        );

  const taskTable = _.uniqBy(
    [
      ...(listTasksByOrgIdData?.listTasksByOrgId?.items.map((task) => ({
        ...task,
        reviewers: task?.reviewers?.items?.map((item) => item?.assigneeId),
        owned: true,
      })) || []),
      ...(allTasksAvailableToOrg?.listTaskOrgs?.items
        ?.map((taskOrg) => ({
          ...taskOrg.task,
          ownerOrg: taskOrg?.org?.name,
        }))
        ?.map((task) => ({
          ...task,
          owned: false,
        })) || []),
      ...(releasedTemplatesChallenges?.map((challenge) => ({
        ...challenge,
        owned: challenge?.orgId === globalSnap?.orgId,
      })) || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');

  const allTasks = Authorization.hasPermission(globalSnap?.permissions, [
    PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
  ])
    ? taskTable
    : canCreateAndReview
    ? [
        ...(taskTable?.filter((t) => t?.userId === globalSnap?.userId) || []),
        ...(taskTable?.filter((t) =>
          t?.reviewers?.includes(globalSnap?.userId),
        ) || []),
      ]
    : (Authorization.canCreate(globalSnap?.permissions) &&
        taskTable?.filter((t) => t?.userId === globalSnap?.userId)) ||
      (Authorization.canReviewContent(globalSnap?.permissions) &&
        taskTable?.filter((t) => t?.reviewers?.includes(globalSnap?.userId)));

  // all events including shared
  const allEventsData = [
    ...(listAssessmentsByOrgData?.listAssessmentsByOrg?.items || []),
    ...(listSharedAssessmentsByOrgData?.listSharedAssessmentBySharedOrgId?.items?.map(
      (sa) => sa?.assessment,
    ) || []),
  ];

  const eventsData =
    allEventsData
      ?.filter((e) => e?.participantEventType !== 'TRAINING')
      ?.map((assessment) => {
        const _eventType =
          assessment?.participantEventType === 'ASSESSMENT' ? 'assess' : 'comp';
        const eventUrl = `${window.origin}/a/login/e/${_eventType}/${assessment?.id}/org/${globalSnap.orgId}`;

        return {
          ...assessment,
          creator: assessment?.creator?.name,
          startDateTime: assessment.startDateTime
            ? getDateTimeZoneFormatted(assessment.startDateTime, true)
            : '-',
          endDateTime: assessment.endDateTime
            ? getDateTimeZoneFormatted(assessment.endDateTime, true)
            : '-',
          status:
            !assessment.startDateTime && !assessment.endDateTime
              ? 'Not Started'
              : assessment &&
                serverTime &&
                getAssessmentStatus(
                  assessment?.startDateTime,
                  assessment?.endDateTime,
                  new Date(serverTime?.getSystemTime),
                ),
          archived: assessment.status === 'ARCHIVED',
          type: assessment.teamBased ? 'Team' : 'Individual',
          parts: assessment?.tasks?.items?.length,
          eventLink: !assessment?.teamBased && (
            <Button
              onClick={() => {
                navigator.clipboard.writeText(eventUrl);
              }}
              style={{ height: '48px' }}
            >
              <ContentCopy />
            </Button>
          ),
          icon: (
            <Box style={productStyle(assessment?.participantEventType, theme)}>
              {assessment?.participantEventType === 'ASSESSMENT' ? (
                <Assignment style={{ height: '18px' }} />
              ) : (
                <EmojiEvents style={{ height: '18px' }} />
              )}
            </Box>
          ),
        };
      }) || [];

  const allCompetitions = eventsData?.filter(
    (allCompetition) =>
      allCompetition.participantEventType === 'COMPETITION' &&
      !allCompetition?.archived,
  );

  const allAssessments = eventsData?.filter(
    (allAssessment) =>
      allAssessment.participantEventType !== 'COMPETITION' &&
      !allAssessment?.archived,
  );

  const allArchivedEvents = eventsData?.filter((item) => item?.archived);

  const getUnreadMessageUserCount = (_items) => {
    // no of users who have send messages which are unread
    const noOfWaitingUsers = _items.reduce(
      (acc, i) =>
        acc +
        (i.messages?.items?.filter((m) => m?.sender?.id === i.userId)?.length >
        0
          ? 1
          : 0),
      0,
    );
    return noOfWaitingUsers;
  };

  const allCourses =
    listCoursesByOrgIdData?.listCoursesByOrgId?.items.map((ac) => {
      return {
        ...ac,
        id: ac.id,
        creator: ac.user.name,
        unReadMessagesUserCount: getUnreadMessageUserCount(
          ac.courseUsers?.items,
        ),
        icon: (
          <Box style={productStyle('COURSE', theme)}>
            <WbIncandescent
              style={{
                transform: 'rotateX(180deg)',
                marginBottom: '5px',
                height: '18px',
              }}
            />
          </Box>
        ),
        parts: ac?.courseModules?.items?.length,
        courseUserId:
          ac?.status === 'AVAILABLE' &&
          ac?.courseUsers?.items?.find(
            (courseUser) => courseUser?.userId === globalSnap?.userId,
          )?.id,
        status: !ac.availability
          ? getCourseStatus.NOT_AVAILABLE
          : getCourseStatus[ac.status],
      };
    }) || [];

  const numberOfEvents =
    (allCompetitions.length > 0 ? 1 : 0) +
    (allAssessments.length > 0 ? 1 : 0) +
    (allCourses.length > 0 ? 1 : 0) +
    (allArchivedEvents.length > 0 ? 1 : 0);

  const allEvents = sortedByCreatedDate([
    ...allAssessments,
    ...allCompetitions,
    ...allCourses,
  ]);

  const tabLabel = [
    {
      label: (
        <Box mr={1} ml={1}>
          <FdTypography variant="body2">All</FdTypography>
        </Box>
      ),
      path: '/all',
      index: 0,
      data: (
        <FdSkeleton loading={listAssessmentsByOrgLoading} height="450px">
          <EventTable
            rows={allEvents}
            onDeleteCourse={refetchCourses}
            refetchAssessmentData={refetchAssessmentData}
            filterType="all"
          />
        </FdSkeleton>
      ),
      rowCount: numberOfEvents,
    },
    {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('COMPETITION', theme)} mr={0.5}>
            <EmojiEvents />
          </Box>
          <FdTypography variant="body2">{`Competitions (${allCompetitions?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allCompetitions',
      index: 1,
      data: (
        <FdSkeleton loading={listAssessmentsByOrgLoading} height="450px">
          <EventTable
            rows={sortedByCreatedDate([...allCompetitions])}
            refetchAssessmentData={refetchAssessmentData}
            filterType="competitions"
          />
        </FdSkeleton>
      ),
      rowCount: allCompetitions?.length,
    },
    {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('COURSE', theme)} mr={0.5}>
            <WbIncandescent
              style={{
                transform: 'rotateX(180deg)',
              }}
            />
          </Box>
          <FdTypography variant="body2">{`Courses (${allCourses?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allCourses',
      index: 2,
      data: (
        <FdSkeleton loading={listCoursesByOrgIdLoading} height="450px">
          <EventTable
            rows={sortedByCreatedDate([...allCourses])}
            onDeleteCourse={refetchCourses}
            filterType="courses"
          />
        </FdSkeleton>
      ),
      rowCount: allCourses?.length,
    },
    {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('ASSESSMENT', theme)} mr={0.5}>
            <Assignment />
          </Box>
          <FdTypography variant="body2">{`Assessments (${allAssessments?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allAssessments',
      index: 3,
      data: (
        <FdSkeleton loading={listAssessmentsByOrgLoading} height="450px">
          <EventTable
            rows={sortedByCreatedDate([...allAssessments])}
            refetchAssessmentData={refetchAssessmentData}
            filterType="assessments"
          />
        </FdSkeleton>
      ),
      rowCount: allAssessments?.length,
    },
    {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('', theme)} mr={0.5}>
            <Archive />
          </Box>
          <FdTypography variant="body2">{`Archived (${allArchivedEvents?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allArchivedEvents',
      index: 4,
      data: (
        <FdSkeleton loading={listAssessmentsByOrgLoading} height="450px">
          <EventTable
            rows={sortedByCreatedDate([...allArchivedEvents])}
            refetchAssessmentData={refetchAssessmentData}
            filterType="archived"
          />
        </FdSkeleton>
      ),
      rowCount: allArchivedEvents?.length,
    },
  ];
  const tabsToRender =
    listAssessmentsByOrgLoading || listCoursesByOrgIdLoading
      ? tabLabel
      : tabLabel?.filter((t) => t.rowCount > 0);

  return (
    <BasePage data-cy="welcome-card">
      <AffiliationLinkStatusModal />
      <FdCard elevation={0}>
        <Box className="flex items-center gap-x-4 w-full">
          <FdTypography
            variant="h3"
            color="primary"
          >{`Hello ${globalSnap.userName}`}</FdTypography>
          <AffiliationTag />
          <SquadTag />
        </Box>
        <Box mt={1}>
          <FdTypography variant="body2" color="secondary">
            Welcome to FifthDomain. From this page, you can view a snapshot of
            content, events and users in your organisation.
          </FdTypography>
        </Box>
      </FdCard>
      {hasManageEvents && (
        <FdCard
          elevation={0}
          style={{ marginTop: '0.8rem' }}
          heading={
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ marginBottom: '-32px' }}
            >
              <FdTypography variant="subtitle1">
                Events in this organisation
              </FdTypography>
              <Box width="160px" height="40px">
                {Authorization.canManageEvents(globalSnap?.permissions) && (
                  <FdDelayed triggerField={createEventType}>
                    <Box mx={1} className={classes.styledSelect}>
                      <FdSelect
                        options={
                          orgProducts?.map(
                            (product) => ORG_PRODUCTS[product],
                          ) || []
                        }
                        width="160px"
                        placeholder="CREATE"
                        customPlaceHolder
                        onChange={(_value) => {
                          const handleOpenModal = () => {
                            setCreateEventType(_value);
                            setOpenModal(_value);
                          };
                          switch (_value) {
                            case 'Course':
                              return singleSpa.navigateToUrl(
                                '/labs/courses/create',
                              );
                            case 'Competition':
                              return singleSpa.navigateToUrl(
                                '/competitions/create',
                              );
                            case 'Assessment':
                              return pricingTier === 'STARTER'
                                ? singleSpa.navigateToUrl(
                                    '/assessor/create/template',
                                  )
                                : handleOpenModal();
                            default:
                              return '/landing';
                          }
                        }}
                        data-cy="create-event-select"
                      />
                    </Box>
                  </FdDelayed>
                )}
              </Box>
            </Box>
          }
        >
          {numberOfEvents === 0 &&
          !listAssessmentsByOrgLoading &&
          !listCoursesByOrgIdLoading ? (
            <Box
              height="440px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={NoContentImage} alt="no-content-data" />
            </Box>
          ) : (
            <FdTab label={tabsToRender?.filter((item) => item)} />
          )}
        </FdCard>
      )}

      {pricingTier !== 'STARTER' && hasManageContent && (
        <Grid container spacing={2} style={{ paddingTop: '5px' }}>
          {hasManageUser && (
            <Grid item xs={3}>
              <FdSkeleton
                loading={listCoursesByOrgIdLoading}
                height="530px"
                animation="wave"
              >
                <OrgUsers
                  listCoursesByOrgIdData={listCoursesByOrgIdData}
                  eventsData={eventsData}
                />
              </FdSkeleton>
            </Grid>
          )}
          <Grid
            item
            // Tempory hiding as per SD-3254 will be needed in future
            // xs={isAdmin ? (hasManageUser ? 7 : 10) : hasManageUser ? 9 : 12}
            xs={hasManageUser ? 9 : 12}
          >
            <Summary
              loading={
                listTasksByOrgIdLoading ||
                allTasksAvailableLoading ||
                templatesLoading
              }
              data={[
                {
                  value: 'Your Content',
                },
                {
                  value: allTasks?.length,
                  description: 'No. of Challenges',
                },
                {
                  value: [
                    ...new Set(
                      allTasks?.map((e) => e.specialty?.name).filter((u) => u),
                    ),
                  ].length,
                  description: 'No. of Professional Specialties',
                },
                {
                  value: Authorization.canCreate(globalSnap.permissions) && (
                    <FdButton
                      onClick={() => {
                        singleSpa.navigateToUrl('/tasks');
                      }}
                    >
                      view
                    </FdButton>
                  ),
                },
              ]}
            />
            <CompositionGraphs
              allTasks={allTasks}
              loading={
                listTasksByOrgIdLoading ||
                allTasksAvailableLoading ||
                templatesLoading
              }
            />
          </Grid>
          {/*
          Tempory hiding as per SD-3254 will be needed in future
            {isAdmin && (
            <Grid item xs={2}>
              <FdCard
                elevation={0}
                style={{
                  height: '530px',
                  backgroundColor: 'rgb(229, 246, 253)',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  minHeight="300px"
                >
                  <Box display="flex" flexDirection="row">
                    <ShoppingCart
                      style={{
                        height: '24px',
                        marginRight: '3px',
                        color: '#00897B',
                      }}
                    />
                    <FdTypography variant="subtitle1">
                      Need more challenges?
                    </FdTypography>
                  </Box>
                  <FdTypography variant="body2">
                    Explore the FifthDomain Challenges Store! Click the “Browse
                    Store” button below to access more challenges to add to your
                    organisation&apos;s library.
                  </FdTypography>
                  <FdButton
                    size="medium"
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/org/content-lease-catalogue`,
                        '_blank',
                      );
                    }}
                  >
                    browse store
                  </FdButton>
                </Box>
              </FdCard>
            </Grid>
          )} */}
        </Grid>
      )}
      <FdModal
        title={
          <Box display="flex" justifyContent="center">
            {`Select ${openModal} Creation Option`}
          </Box>
        }
        description={
          <>
            <FdTypography variant="body1" color="secondary">
              {`Please select whether you wish to create an ${openModal} using a
            FifthDomain template or create your own custom ${openModal}.`}
            </FdTypography>
            <Box display="flex" flexDirection="row" mt={2}>
              <FdCard
                style={{
                  border:
                    selectedTemplateOption === 'withTemplate'
                      ? '2px solid #1976D2'
                      : 'none',
                }}
              >
                <Ballot />
                <FdTypography variant="subtitle1">{`Create ${openModal} using Template`}</FdTypography>
                <Box my={2}>
                  <FdTypography
                    variant="body2"
                    color="secondary"
                  >{`Choose a ready-to-go FifthDomain ${openModal} template, fill in required details, and invite your participants!`}</FdTypography>
                </Box>
                <FdButton
                  style={{ width: '100%' }}
                  onClick={() => {
                    setSelectedTemplateOption('withTemplate');
                  }}
                >
                  select
                </FdButton>
              </FdCard>
              <FdCard
                style={{
                  marginLeft: '24px',
                  border:
                    selectedTemplateOption === 'noTemplate'
                      ? '2px solid #1976D2'
                      : 'none',
                }}
              >
                <Create />
                <FdTypography variant="subtitle1">{`Create Custom ${openModal} `}</FdTypography>
                <Box my={2}>
                  <FdTypography
                    variant="body2"
                    color="secondary"
                  >{`Create a new custom ${openModal} that is fit-for-purpose for your organisation’s unique assessment needs!`}</FdTypography>
                </Box>
                <FdButton
                  style={{ width: '100%' }}
                  onClick={() => {
                    setSelectedTemplateOption('noTemplate');
                  }}
                >
                  select
                </FdButton>
              </FdCard>
            </Box>
          </>
        }
        confirm="PROCEED"
        dismiss="CANCEL"
        open={openModal}
        onConfirm={async () => {
          const urlValue = {
            // hiding competitions temporary
            // Competition: 'competitions',
            Assessment: 'assessor',
          };
          const url =
            selectedTemplateOption === 'withTemplate'
              ? `/${urlValue[openModal]}/create/template`
              : `/${urlValue[openModal]}/create`;
          singleSpa.navigateToUrl(url);

          setOpenModal(undefined);
        }}
        onDismiss={() => {
          setOpenModal(undefined);
          setSelectedTemplateOption(null);
          setCreateEventType(undefined);
        }}
      />
    </BasePage>
  );
};
export default AdminLandingPage;
