import React from 'react';
import singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import TeamCard from './TeamCard';

const TeamCards = ({ teams }) => {
  const onViewTeam = (team) => {
    singleSpa.navigateToUrl(`/competitions/view-team/${team?.id}`);
  };

  return (
    <Box className="flex gap-4 w-full flex-wrap">
      {teams?.map((t) => (
        <TeamCard team={t?.team} onViewTeam={onViewTeam} />
      ))}
    </Box>
  );
};

TeamCards.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TeamCards;
