import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, useTheme } from '@material-ui/core';
import {
  FdTypography,
  FdSkeleton,
  useSnapshot,
  globalStore,
  FdIconsV5,
} from '@fifthdomain/fe-shared';
import { listAffliationsByUserId } from '../../graphql/queries';

const AffiliationTag = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const { LinkRounded } = FdIconsV5;

  const { data: affiliationData, loading: affiliationDataLoading } = useQuery(
    gql(listAffliationsByUserId),
    {
      variables: {
        userId: globalSnap?.userId,
      },
    },
  );
  const affiliationDetails =
    affiliationData?.listAffliationsByUserId?.items || [];
  const orgName = affiliationDetails?.filter((a) => a?.status === 'ACTIVE')?.[0]
    ?.org?.name;

  return (
    <FdSkeleton height={24} loading={affiliationDataLoading} width={200}>
      <Box>
        {orgName && (
          <Box
            className="flex items-center h-24px px-4 py-1 rounded gap-x-2"
            style={{
              backgroundColor:
                theme?.palette?.type === 'dark'
                  ? '#20228F'
                  : 'rgba(227, 230, 236, 1)',
            }}
          >
            <LinkRounded fontSize="small" />
            <FdTypography
              variant="captiontext1"
              style={{ fontWeight: 500 }}
            >{`Affiliated to ${orgName}`}</FdTypography>
          </Box>
        )}
      </Box>
    </FdSkeleton>
  );
};

export default AffiliationTag;
