import { addSeconds } from 'date-fns';
import { proxy } from '@fifthdomain/fe-shared';

const appStore = proxy({
  systemDate: null,
  systemDateTimer: null,
  startSystemDateTimer(val) {
    this.systemDate = val;
    this.systemDateTimer = setInterval(() => {
      this.systemDate = addSeconds(this.systemDate, 1);
    }, 1000);
  },
  stopSystemDateTimer() {
    clearInterval(this.systemDateTimer);
  },
});

export default appStore;
