import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import {
  FdTypography,
  FdSkeleton,
  FdCircularProgressBar,
  useQueryRecursive,
  globalStore,
  useSnapshot,
  FdProgress,
} from '@fifthdomain/fe-shared';
import { getUserAssessmentForProgress } from '../../queries/customQueries';
import {
  getCompletedAssessmentTasksCount,
  getTotal,
} from '../../shared/utils/taskUtils';
import {
  listScoreboardsByTeamId,
  listUserScoreboardsByUserId,
} from '../../graphql/queries';

const CardProgressBar = ({ content, linearProgress }) => {
  const globalSnap = useSnapshot(globalStore);
  const {
    contentType,
    courseProgress,
    tasksCount,
    id,
    userAssessmentAssessmentId,
  } = content || {};

  const { data: assessmentsData, loading: assessmentsLoading } = useQuery(
    gql(getUserAssessmentForProgress),
    {
      variables: {
        id,
      },
    },
  );

  const { data: teamScoreboardData, loading: teamScoreboardLoading } =
    useQueryRecursive(gql(listScoreboardsByTeamId), {
      variables: {
        teamId: content.teamId,
      },
      skip: !content.teamId,
    });

  const { data: userScoreboardData, loading: userScoreboardLoading } =
    useQueryRecursive(gql(listUserScoreboardsByUserId), {
      variables: {
        userId: globalSnap?.userId,
      },
      skip: content.teamId,
    });

  const { assessment } = assessmentsData?.getUserAssessment || {};
  const achievedPoints =
    (content?.teamId
      ? teamScoreboardData?.listScoreboardsByTeamId?.items?.find(
          (s) => s?.assessmentId === userAssessmentAssessmentId,
        )?.points
      : userScoreboardData?.listUserScoreboardsByUserId?.items?.find(
          (s) => s?.assessmentId === userAssessmentAssessmentId,
        )?.points) || 0;

  const totalPoints =
    getTotal(
      assessment?.multiLevel
        ? assessment?.levels?.items
            ?.map((l) =>
              l.tasks?.items.map((_task) => ({
                ..._task.task,
              })),
            )
            .flat()
        : assessment?.tasks.items.map((_task) => ({
            ..._task.task,
          })),
      'recommendedPoints',
    ) || 1;

  const completedTasksCount = getCompletedAssessmentTasksCount(
    assessment?.tasks.items,
    id,
  );
  const tasksCountDerived = tasksCount === 0 ? 1 : tasksCount;

  const eventProgressValue =
    contentType === 'course'
      ? courseProgress
      : contentType === 'assessment'
      ? (completedTasksCount / tasksCount) * 100
      : (achievedPoints / totalPoints) * 100;

  return (
    <Box
      className={`flex ${
        linearProgress ? '' : 'flex-col'
      } items-center justify-center`}
    >
      <FdSkeleton
        loading={
          assessmentsLoading || teamScoreboardLoading || userScoreboardLoading
        }
        height="30"
        width={linearProgress ? 0 : 150}
      >
        {linearProgress ? (
          <Box mr={1}>
            <FdProgress type="linear" value={eventProgressValue} />
          </Box>
        ) : (
          <Box width={25} height={30}>
            <FdCircularProgressBar value={eventProgressValue} />
          </Box>
        )}
        <Box pt={linearProgress ? 0 : 0.5}>
          {contentType === 'course' ? (
            <FdTypography variant="captiontext2">{`${courseProgress}% complete`}</FdTypography>
          ) : contentType === 'assessment' ? (
            <FdTypography variant="captiontext2">{`${completedTasksCount}/${tasksCountDerived} challenges`}</FdTypography>
          ) : (
            <FdTypography variant="captiontext2">{`${achievedPoints}/${totalPoints} points`}</FdTypography>
          )}
        </Box>
      </FdSkeleton>
    </Box>
  );
};

CardProgressBar.propTypes = {
  content: PropTypes.shape({ teamId: PropTypes.string }).isRequired,
  linearProgress: PropTypes.bool,
};

CardProgressBar.defaultProps = {
  linearProgress: false,
};

export default CardProgressBar;
