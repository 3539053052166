export const listTaskAttemptAggregatesForCapabilityProfile = /* GraphQL */ `
  query ListTaskAttemptAggregates(
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        points
        status
        assessment {
          levels {
            items {
              tasks {
                items {
                  task {
                    recommendedPoints
                  }
                }
              }
            }
          }
          tasks {
            items {
              task {
                recommendedPoints
              }
            }
          }
          id
          name
          status
          multiLevel
          participantEventType
          updatedAt
          startDateTime
          endDateTime
        }
        attempts
        task {
          category
          specialtyId
          specialty {
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const listQuizAttemptsByUserId = /* GraphQL */ `
  query ListQuizAttemptsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuizAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizAttemptsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseId
        course {
          name
        }
        quizId
        success
        question {
          competencies {
            items {
              area {
                areaName
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listUrls = /* GraphQL */ `
  query ListUrls(
    $filter: ModelUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUrls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        theme
        url
        key
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        userAssessmentAssessmentId
        status
      }
      nextToken
    }
  }
`;
export const listUserAssessmentsLandingPage = /* GraphQL */ `
  query listUserAssessmentsByUserId(
    $userId: ID!
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessmentsByUserId(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastActive
        userId
        assessment {
          id
          orgId
          creator {
            org {
              name
            }
          }
          name
          description
          status
          teamBased
          startDateTime
          endDateTime
          hours
          guided
          minutes
          createdBy
          participantEventType
          multiLevel
          tasks {
            items {
              id
            }
          }
        }
        userAssessmentAssessmentId
        teamId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getUserAssessmentForProgress = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      assessment {
        id
        multiLevel
        levels {
          items {
            tasks {
              items {
                id
                task {
                  id
                  recommendedPoints
                  attempts(filter: { success: { eq: true } }) {
                    items {
                      id
                      success
                      assessmentId
                      taskAttemptUserAssessmentId
                      taskAttemptTaskId
                      task {
                        recommendedPoints
                      }
                    }
                  }
                }
              }
            }
          }
        }
        tasks {
          items {
            id
            task {
              id
              recommendedPoints
              attempts(filter: { success: { eq: true } }) {
                items {
                  id
                  success
                  assessmentId
                  taskAttemptUserAssessmentId
                  taskAttemptTaskId
                  task {
                    recommendedPoints
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
    }
  }
`;

export const listScoreboards = /* GraphQL */ `
  query ListScoreboards(
    $filter: ModelScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        assessment {
          participantEventType
          orgId
          status
          teamBased
        }
        userId
        user {
          alias
          participantType
        }
        teamId
        team {
          alias
          name
          members {
            items {
              userId
              User {
                alias
              }
            }
          }
          key
        }
        points
        firstBloods
        assessmentId
        successRate
      }
      nextToken
    }
  }
`;

export const listScoreboardsByEventOrgId = /* GraphQL */ `
  query listScoreboardsByEventOrgId(
    $eventOrgId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreboardsByEventOrgId(
      eventOrgId: $eventOrgId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        assessment {
          participantEventType
          orgId
          status
          teamBased
        }
        user {
          alias
          participantType
        }
        team {
          alias
          name
          members {
            items {
              userId
              User {
                alias
              }
            }
          }
          key
        }
        id
        userId
        groupId
        teamId
        flags
        firstBloods
        points
        successRate
        assessmentId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listUserScoreboardsByEventOrgId = /* GraphQL */ `
  query listUserScoreboardsByEventOrgId(
    $filter: ModelUserScoreboardFilterInput
    $limit: Int
    $nextToken: String
    $eventOrgId: ID!
    $sortDirection: ModelSortDirection
    $userId: ModelIDKeyConditionInput
  ) {
    listUserScoreboardsByEventOrgId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      eventOrgId: $eventOrgId
      sortDirection: $sortDirection
      userId: $userId
    ) {
      items {
        assessment {
          participantEventType
          orgId
          status
          teamBased
        }
        userId
        user {
          alias
          participantType
        }
        teamId
        team {
          alias
          name
          members {
            items {
              userId
              User {
                alias
              }
            }
          }
          key
        }
        points
        firstBloods
        assessmentId
        successScore
      }
      nextToken
    }
  }
`;

export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        participantEventType
        status
        name
        teams(filter: { status: { ne: REMOVED } }) {
          items {
            teamId
          }
        }
        teamBased
        users(filter: { status: { ne: REMOVED } }) {
          items {
            userId
            status
          }
        }
      }
      nextToken
    }
  }
`;
export const listAffliationRequestsByOrgId = /* GraphQL */ `
  query ListAffliationRequestsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAffliationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationRequestsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        status
        user {
          permissions
        }
      }
      nextToken
    }
  }
`;
export const listNonAffliatedUserEventsByOrgId = /* GraphQL */ `
  query ListNonAffliatedUserEventsByOrgId(
    $orgId: ID!
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNonAffliatedUserEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonAffliatedUserEventsByOrgId(
      orgId: $orgId
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        eventId
        eventType
        orgId
        email
        user {
          name
        }
      }
      nextToken
      __typename
    }
  }
`;
