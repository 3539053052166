import { isValidMobile } from '@fifthdomain/fe-shared';
import Yup from '../Common';

export const initialValues = {
  name: '',
  alias: '',
  ageBracket: '',
  phone: '',
  currentOccupation: '',
  location: '',
  workStatus: '',
  file: '',
  linkedIn: '',
  gitlab: '',
  website: '',
  jobPool: true,
  gender: '',
  neurodiversity: '',
  ethnicity: '',
  remote: '',
  workArrangement: '',
  relocate: '',
  salaryRange: [],
  addAccomplishment: [],
  accomplishmentsToDelete: [],
  avatar: undefined,
  key: '',
  error: false,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a valid name.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please enter a valid name.',
      (value) => value && value.length === value.trim().length,
    )
    .max(150, 'Full Name cannot be more than 150 characters long.')
    .test(
      'noSpecialCharacterOrDigits',
      'Please enter a valid name.',
      (value) => !/^.*[!@#$%^&*0-9]/.test(value),
    ),
  alias: Yup.string()
    .required('Please enter a valid alias.')
    .max(30, 'Alias cannot be more than 30 characters long.')
    .test('valid alias', 'Please enter a valid alias.', (value) =>
      /^[A-Za-z0-9 ]*$/.test(value),
    )
    .test(
      'noTrailing&LeadingWhitespace',
      'Please enter a valid alias',
      (value) => value && value.length === value.trim().length,
    ),
  phone: Yup.string()
    .matches(/^(\+\d+|)$/, 'Please include your country code (eg. +61)')
    .test('validMobileNumber', 'Please enter a valid mobile number.', (value) =>
      isValidMobile(value),
    ),
  location: Yup.string()
    .required('Please select a location.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please select a location.',
      (value) => value && value.length === value.trim().length,
    ),
  workStatus: Yup.string()
    .required('Please select a right to work status.')
    .test(
      'noTrailing&LeadingWhitespace',
      'Please select a right to work status.',
      (value) => value && value.length === value.trim().length,
    ),
  key: Yup.string(),
  avatar: Yup.mixed(),
  error: Yup.bool().oneOf([false]),
  addAccomplishment: Yup.array().of(
    Yup.object().shape({
      accIndex: Yup.string(),
      type: Yup.string().required('Please select a accomplishment type'),
      accName: Yup.string()
        .required('This field is required.')
        .max(
          200,
          'Character limit exceeded. Please shorten your input to 200 characters or less.',
        )
        .noWhitespace('This field is required.'),
      providerName: Yup.string().when(['type'], {
        is: (type) => type !== 'Other Accomplishment',
        then: Yup.string()
          .required('This field is required.')
          .max(
            100,
            'Character limit exceeded. Please shorten your input to 100 characters or less.',
          )
          .noWhitespace('This field is required.'),
        otherwise: Yup.string().nullable(),
      }),
      yearAwarded: Yup.string().test(
        'noInvalidYear',
        'Please enter a valid year.',
        (value) => (value ? value?.match(/^\d{4}$/) : true),
      ),
      startYear: Yup.string().test(
        'noInvalidYear',
        'Please enter a valid year.',
        (value) => (value ? value?.match(/^\d{4}$/) : true),
      ),
      endYear: Yup.string().test(
        'noInvalidYear',
        'Please enter a valid year.',
        (value) => (value ? value?.match(/^\d{4}$/) : true),
      ),
    }),
  ),
  accomplishmentsToDelete: Yup.array().of(Yup.string()),
  currentOccupation: Yup.string().max(
    150,
    'Occupation cannot be more than 150 characters long.',
  ),
  linkedIn: Yup.string().max(
    300,
    'URLs cannot be more than 300 characters long.',
  ),
  gitlab: Yup.string().max(
    300,
    'URLs cannot be more than 300 characters long.',
  ),
  website: Yup.string().max(
    300,
    'URLs cannot be more than 300 characters long.',
  ),
  salaryRange: Yup.array()
    .of(Yup.number())
    .test(
      'is-zero',
      'Starting value cannot equal zero. ',
      (value) => value && value[0] !== 0,
    ),
});
