import React from 'react';
import { Box } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { FdTypography } from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';

const ProfileView = ({ title, defaultValue }) => {
  const { getValues } = useFormContext();

  const getUserProfileValue = (_value) => {
    switch (_value) {
      case 'linkedIn':
      case 'gitlab':
      case 'website':
      case 'gender':
      case 'ethnicity':
        return getValues(_value) || defaultValue;
      case 'neurodiversity':
      case 'remote':
      case 'workArrangement':
        return getValues(_value)?.value || getValues(_value) || defaultValue;
      case 'relocate':
        return (getValues(_value) && 'Yes') || defaultValue;
      case 'salaryRange':
        return getValues().salaryRange[0] !== 0
          ? `${getValues().salaryRange[0]}k - ${getValues().salaryRange[1]}k`
          : defaultValue;
      default:
        return defaultValue;
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      {title?.map((t) => (
        <Box mb={3}>
          <FdTypography variant="subtitle1">{t?.label}</FdTypography>
          <FdTypography variant="subtitle1" color="secondary">
            {getUserProfileValue(t?.value)}
          </FdTypography>
        </Box>
      ))}
    </Box>
  );
};

ProfileView.propTypes = {
  title: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.string,
};

ProfileView.defaultProps = {
  title: [],
  defaultValue: '',
};

export default ProfileView;
