import React from 'react';
import _ from 'lodash';
import { Box, Divider, useTheme, Card, CardContent } from '@material-ui/core';
import { gql } from '@apollo/client';
import {
  BasePage,
  FdTypography,
  FdSkeleton,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTab,
  FdIconsV5,
} from '@fifthdomain/fe-shared';
import { HallOfFameIcon } from '../components/Affiliated/Icons/HallOfFameIcon';
import TopIndividuals from '../components/Affiliated/HallOfFame/TopIndividuals';
import TopTeams from '../components/Affiliated/HallOfFame/TopTeams';
import {
  listAssessments,
  listScoreboardsByEventOrgId,
  listUserScoreboardsByEventOrgId,
} from '../queries/customQueries';

const HallOfFame = () => {
  const globalSnap = useSnapshot(globalStore);
  const { Business } = FdIconsV5;
  const theme = useTheme();

  const { data: assessmentsData, loading: assessmentsLoading } =
    useQueryRecursive(gql(listAssessments), {
      variables: {
        filter: {
          orgId: { eq: globalSnap?.orgId },
          participantEventType: { eq: 'COMPETITION' },
        },
        limit: 500,
      },
    });

  const { data: scoreboardsData, loading: scoreboardsLoading } =
    useQueryRecursive(gql(listScoreboardsByEventOrgId), {
      variables: {
        eventOrgId: globalSnap.orgId,
      },
      staleTime: { seconds: 0 },
      skip: !globalSnap.orgId,
    });

  const orgScoreboards =
    scoreboardsData?.listScoreboardsByEventOrgId?.items || [];

  const { data: userScoreboardsData, loading: userScoreboardsLoading } =
    useQueryRecursive(gql(listUserScoreboardsByEventOrgId), {
      variables: {
        eventOrgId: globalSnap.orgId,
        limit: 500,
      },
      skip: !globalSnap.orgId,
    });
  const allUserScoreboardsData =
    userScoreboardsData?.listUserScoreboardsByEventOrgId?.items || [];

  const competitions =
    assessmentsData?.listAssessments?.items?.filter((a) =>
      ['FINISHED', 'STARTED', 'ARCHIVED'].includes(a?.status),
    ) || [];

  const top100Individuals = _(allUserScoreboardsData)
    .groupBy((item) => _.get(item, 'userId'))
    .map((items, userId) => {
      const { user } = items[0];
      const totalPoints = items?.reduce((acc, i) => acc + (i?.points || 0), 0);
      const totalFirstBloods = items?.reduce(
        (acc, i) => acc + (i?.firstBloods || 0),
        0,
      );
      const avgSuccessRate =
        items?.length > 0
          ? Math.round(
              items.reduce((acc, i) => acc + (i?.successScore || 0), 0) /
                items.length,
            )
          : 0;
      const competitionsCount = items?.reduce(
        (acc, i) =>
          acc + (i?.assessment?.participantEventType === 'COMPETITION' ? 1 : 0),
        0,
      );

      return {
        id: userId,
        alias: user?.alias,
        affiliationStatus:
          user?.participantType === 'INTERNAL'
            ? 'Affiliated'
            : 'Non-Affiliated',
        points: totalPoints,
        firstBloods: totalFirstBloods,
        successRate: avgSuccessRate,
        competitionsCount,
      };
    })
    .sort((a, b) => b.points - a.points)
    .map((i, index) => ({ ...i, rank: index + 1 }))
    .value()
    .slice(0, 100);

  const top100Teams = _(orgScoreboards)
    .filter((t) => t?.teamId)
    .groupBy((item) => _.get(item, 'teamId'))
    .map((items, teamId) => {
      const { team } = items[0];
      const totalPoints = items?.reduce((acc, i) => acc + (i?.points || 0), 0);
      const totalFirstBloods = items?.reduce(
        (acc, i) => acc + (i?.firstBloods || 0),
        0,
      );
      const avgSuccessRate =
        items?.length > 0
          ? Math.round(
              items.reduce((acc, i) => acc + (i?.successRate || 0), 0) /
                items.length,
            )
          : 0;
      const competitionsCount = items?.reduce(
        (acc, i) =>
          acc + (i?.assessment?.participantEventType === 'COMPETITION' ? 1 : 0),
        0,
      );

      return {
        id: teamId,
        team: { name: team?.name, key: team?.key },
        teamMembers: team?.members?.items || [],
        points: totalPoints,
        firstBloods: totalFirstBloods,
        successRate: avgSuccessRate,
        competitionsCount,
      };
    })
    .sort((a, b) => b.points - a.points)
    .map((i, index) => ({ ...i, rank: index + 1 }))
    .value()
    .slice(0, 100);

  const teamBasedCompetitions = competitions?.filter((c) => c?.teamBased) || [];
  const teamsInParticipation = [
    ...new Set(
      teamBasedCompetitions
        ?.map((t) => t?.teams?.items?.map((tm) => tm?.teamId))
        ?.flat(),
    ),
  ];
  const individualCompetitions =
    competitions?.filter((c) => !c?.teamBased) || [];
  const individualsInParticipation = [
    ...new Set(
      individualCompetitions
        ?.map((t) => t?.users?.items?.map((tm) => tm?.userId))
        ?.flat(),
    ),
  ];

  const tiles = [
    {
      label: 'Total Competitions',
      value: competitions?.length || 0,
    },
    {
      label: 'Individual-Based Competitions',
      value: individualCompetitions?.length || 0,
    },
    {
      label: 'Team-Based Competitions',
      value: teamBasedCompetitions?.length || 0,
    },
    {
      label: 'Total Participating Individuals',
      value: individualsInParticipation?.length || 0,
    },
    {
      label: 'Total Participating Teams',
      value: teamsInParticipation?.length || 0,
    },
  ];

  return (
    <BasePage data-cy="hall-of-fame">
      <Box>
        <FdCard variant="outlined">
          <Box className="flex justify-between items-center">
            <Box className="flex flex-col">
              <Box className="flex items-center mb-3">
                <HallOfFameIcon style={{ fontSize: 26 }} />
                <Box className="flex items-center gap-x-4 ml-2">
                  <FdTypography variant="h3">Hall of Fame</FdTypography>
                  <Box
                    className="flex items-center rounded-2xl py-1 px-3 h-8 gap-x-3"
                    style={{
                      backgroundColor:
                        theme?.palette?.type === 'dark'
                          ? 'rgba(29, 57, 110, 1)'
                          : 'rgba(227, 230, 236, 1)',
                    }}
                  >
                    <Business />
                    <FdTypography variant="body2">
                      {globalSnap.orgName}
                    </FdTypography>
                  </Box>
                </Box>
              </Box>
              <FdTypography
                variant="body2"
                color="secondary"
                style={{ lineHeight: '32px' }}
              >
                {`Welcome to the ${globalSnap?.orgName} Hall of Fame! Discover the outstanding
                achievements within the ${globalSnap?.orgName} community by viewing the top
                individuals and teams, ranked by points. Both affiliated and
                non-affiliated users within ${globalSnap?.orgName} can appear on the ${globalSnap?.orgName} Hall of Fame. However, only affiliated users can see the
                ${globalSnap?.orgName} Hall of Fame.`}
              </FdTypography>
            </Box>
          </Box>
          <Box mt={2}>
            <Card variant="outlined">
              <CardContent style={{ padding: 0 }}>
                <Box className="flex items-center justify-evenly w-full p-0 mx-4">
                  {tiles?.map((t, index) => (
                    <FdSkeleton
                      loading={assessmentsLoading}
                      height={84}
                      width={300}
                    >
                      <Box className="flex flex-col items-center py-5">
                        <Box className="mb-1">
                          <FdTypography variant="subtitle1">
                            {t?.value}
                          </FdTypography>
                        </Box>
                        <FdTypography variant="captiontext1" color="secondary">
                          {t?.label}
                        </FdTypography>
                      </Box>
                      {index !== tiles.length - 1 && (
                        <Divider orientation="vertical" flexItem />
                      )}
                    </FdSkeleton>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </FdCard>
        <Box ml={0.5}>
          <FdTab
            label={[
              {
                label: 'Top 100 Individuals',
                index: 0,
                data: (
                  <FdCard variant="outlined">
                    <Box mb={1}>
                      <FdTypography variant="h3">
                        Top 100 Individuals
                      </FdTypography>
                    </Box>
                    <Box mb={2}>
                      <FdTypography variant="body2" color="secondary">
                        A current list of the top 100 individuals in the
                        organisation, ranked by points, is below. For each
                        individual, you can also view details such as their
                        first solves won and challenge success rate.
                      </FdTypography>
                    </Box>
                    <FdSkeleton loading={userScoreboardsLoading} height={490}>
                      <TopIndividuals rows={top100Individuals} />
                    </FdSkeleton>
                  </FdCard>
                ),
              },
              {
                label: 'Top 100 Teams',
                index: 1,
                data: (
                  <FdCard variant="outlined">
                    <Box mb={1}>
                      <FdTypography variant="h3">Top 100 Teams</FdTypography>
                    </Box>
                    <Box mb={2}>
                      <FdTypography variant="body2" color="secondary">
                        A current list of the top 100 teams in the organisation,
                        ranked by points, is below. For each team, you can also
                        view details such as their first solves won and
                        challenge success rate.
                      </FdTypography>
                    </Box>
                    <FdSkeleton loading={scoreboardsLoading} height={490}>
                      <TopTeams rows={top100Teams} />
                    </FdSkeleton>
                  </FdCard>
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </BasePage>
  );
};

export default HallOfFame;
