import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { FdTypography, FdTextField } from '@fifthdomain/fe-shared';

const AccomplishmentType = ({ accomplishmentType, accIndex }) => {
  const { control, setValue, clearErrors } = useFormContext();

  return accomplishmentType ? (
    <Box>
      <Controller
        control={control}
        name={`addAccomplishment[${accIndex}].accName`}
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Box mt={1} mb={2}>
            <FdTextField
              id={`addAccomplishment[${accIndex}].accName`}
              placeholder={
                accomplishmentType === 'Other Accomplishment'
                  ? 'Enter Accomplishment Description'
                  : `Enter ${accomplishmentType} Name`
              }
              label={
                accomplishmentType === 'Other Accomplishment'
                  ? 'Accomplishment Description'
                  : `${accomplishmentType} Name`
              }
              required
              fullWidth
              error={error}
              helperText={error && error?.message}
              {...rest}
              inputRef={ref}
              onFocus={() => {
                clearErrors();
              }}
            />
          </Box>
        )}
      />

      {accomplishmentType !== 'Other Accomplishment' && (
        <Controller
          control={control}
          name={`addAccomplishment[${accIndex}].providerName`}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id={`addAccomplishment[${accIndex}].providerName`}
                label={
                  accomplishmentType === 'Education/Course'
                    ? 'School Name'
                    : 'Certification Provider Name'
                }
                required
                fullWidth
                placeholder={
                  accomplishmentType === 'Education/Course'
                    ? 'Enter School Name'
                    : 'Enter Certification Provider Name'
                }
                error={error}
                helperText={error && error?.message}
                {...rest}
                inputRef={ref}
                onFocus={() => {
                  clearErrors();
                }}
              />
            </Box>
          )}
        />
      )}

      {accomplishmentType === 'Certification' ? (
        <Controller
          control={control}
          name={`addAccomplishment[${accIndex}].yearAwarded`}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id={`addAccomplishment[${accIndex}].yearAwarded`}
                label="Year Awarded"
                required
                fullWidth
                placeholder="YYYY"
                error={error}
                helperText={error && error?.message}
                {...rest}
                inputRef={ref}
                onFocus={() => {
                  clearErrors();
                }}
              />
            </Box>
          )}
        />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between">
            <Controller
              control={control}
              name={`addAccomplishment[${accIndex}].startYear`}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id={`addAccomplishment[${accIndex}].startYear`}
                    label="Start Year"
                    placeholder="YYYY"
                    required
                    error={error}
                    helperText={error && error?.message}
                    {...rest}
                    inputRef={ref}
                    onFocus={() => {
                      clearErrors();
                    }}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name={`addAccomplishment[${accIndex}].endYear`}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id={`addAccomplishment[${accIndex}].endYear`}
                    label="End Year"
                    required
                    placeholder="YYYY"
                    error={error}
                    helperText={error && error?.message}
                    {...rest}
                    inputRef={ref}
                    onFocus={() => {
                      clearErrors();
                    }}
                  />
                </Box>
              )}
            />
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <Controller
              control={control}
              name={`addAccomplishment[${accIndex}].currentYear`}
              render={({ field: { ref, value: fieldValue, ...rest } }) => (
                <Box mb={1} display="flex" flexDirection="row">
                  <FdTypography variant="subtitle1" style={{ margin: '8px' }}>
                    Current
                  </FdTypography>
                  <FormControlLabel
                    control={
                      <Switch
                        id={`addAccomplishment[${accIndex}].currentYear`}
                        checked={fieldValue}
                        {...rest}
                        onChange={(e) => {
                          setValue(
                            `addAccomplishment[${accIndex}].currentYear`,
                            e.target.checked,
                          );
                        }}
                        inputRef={ref}
                      />
                    }
                  />
                </Box>
              )}
            />
          </Box>
        </>
      )}
    </Box>
  ) : (
    <></>
  );
};

AccomplishmentType.propTypes = {
  accomplishmentType: PropTypes.string.isRequired,
  accIndex: PropTypes.string.isRequired,
};

export default AccomplishmentType;
