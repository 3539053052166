import React, { useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { AuthContext, useSnapshot, globalStore } from '@fifthdomain/fe-shared';

const ParticipantRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        setUser(authUser);
        // add logged in user to global state
        globalStore.user = authUser;
      })
      .catch(() => {
        singleSpa.navigateToUrl('/');
      });
  }, [setUser]);

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;
        if (user && type === 'PARTICIPANT') {
          return <Component {...rest} {...props} />;
        }
        if (user && ['ADMIN', 'MANAGER'].includes(type)) {
          return <Redirect to="/landing" />;
        }
        return null;
      }}
    />
  );
};

ParticipantRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ParticipantRoute;
