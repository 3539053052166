import React from 'react';

export const HallOfFameIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.952148 4.75751L9.99977 -0.00439453L19.0474 4.75751V6.66227H0.952148V4.75751ZM2.85679 8.56703V15.2337H5.71394V8.56703H2.85679ZM8.57108 8.56703V15.2337H11.4282V8.56703H8.57108ZM19.0474 17.1385V19.9956H0.952148V17.1385H19.0474ZM14.2854 8.56703V15.2337H17.1425V8.56703H14.2854Z"
      fill="currentColor"
    />
  </svg>
);
