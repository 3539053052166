import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdButton, FdTypography } from '@fifthdomain/fe-shared';

const FdFileUpload = ({
  onDrop,
  inProgress,
  fileTypes,
  fileCount,
  singleFile,
  uploadPercentage,
  onSingleFileNextAttempt,
}) => {
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: fileTypes,
  });
  const onClickEvent = singleFile
    ? fileCount === 0
      ? open
      : onSingleFileNextAttempt
    : open;
  return (
    <Box>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <FdButton
          variant="secondary"
          onClick={onClickEvent}
          data-cy="upload-btn"
          disabled={inProgress}
          aria-label="add-artifact"
          size="small"
        >
          {fileCount && !singleFile > 0 ? 'Replace' : 'Browse'}
        </FdButton>
      </Box>
      <Box display="flex" justifyContent="center" mt={2} mb={2} width="100%">
        <FdTypography variant="h3" style={{ marginRight: 6 }}>
          {uploadPercentage > 0 && `${Math.round(uploadPercentage * 100)}%`}
        </FdTypography>
        {inProgress && <CircularProgress />}
      </Box>
      <Box mt={1}>
        <FdTypography variant="captiontext1" color="secondary">
          {uploadPercentage > 0 &&
            `Once the file has started uploading, the cancel button won't discard the process. 
              If you press cancel, the upload continues to be processed in the background.`}
        </FdTypography>
      </Box>
    </Box>
  );
};

FdFileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  fileTypes: PropTypes.string,
  fileCount: PropTypes.number,
  singleFile: PropTypes.bool,
  uploadPercentage: PropTypes.number,
  onSingleFileNextAttempt: PropTypes.func,
};

FdFileUpload.defaultProps = {
  inProgress: false,
  fileTypes: '',
  fileCount: 0,
  singleFile: false,
  uploadPercentage: 0.0,
  onSingleFileNextAttempt: () => null,
};

export default FdFileUpload;
