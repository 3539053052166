import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const HighlightedLabel = ({ value }) => {
  const theme = useTheme();
  return (
    <Box
      className="flex items-center justify-center mr-2"
      style={{
        backgroundColor: theme?.fdProColors?.electricBlue[600],
        height: '28px',
        borderRadius: '4px',
      }}
    >
      <FdTypography
        variant="subtitle1"
        style={{ color: '#fff', padding: '0 0.8rem' }}
      >
        {value}
      </FdTypography>
    </Box>
  );
};

HighlightedLabel.propTypes = {
  value: PropTypes.string.isRequired,
};

export default HighlightedLabel;
