import React from 'react';

export const CrownSilverIcon = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 0.6C9.08152 0.6 9.39946 0.726428 9.63388 0.951472C9.8683 1.17652 10 1.48174 10 1.8C10 2.286 9.6875 2.7 9.26875 2.892L8.125 6.69V9.6H1.875V6.69L0.73125 2.892C0.3125 2.7 0 2.286 0 1.8C0 1.48174 0.131696 1.17652 0.366116 0.951472C0.600537 0.726428 0.918479 0.6 1.25 0.6C1.58152 0.6 1.89946 0.726428 2.13388 0.951472C2.3683 1.17652 2.5 1.48174 2.5 1.8C2.5 2.1 2.3875 2.37 2.1875 2.58L3.9375 4.41L4.26875 2.172C3.95625 1.956 3.75 1.602 3.75 1.2C3.75 0.88174 3.8817 0.576515 4.11612 0.351472C4.35054 0.126428 4.66848 0 5 0C5.33152 0 5.64946 0.126428 5.88388 0.351472C6.1183 0.576515 6.25 0.88174 6.25 1.2C6.25 1.602 6.04375 1.956 5.73125 2.172L6.0625 4.41L7.79375 2.574C7.6125 2.364 7.5 2.1 7.5 1.8C7.5 1.48174 7.6317 1.17652 7.86612 0.951472C8.10054 0.726428 8.41848 0.6 8.75 0.6ZM0.625 10.8H9.375V12H0.625V10.8Z"
      fill="#90A4AE"
    />
  </svg>
);
