import React from 'react';

const TrainingSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.85"
        d="M5.72086 6.66L4.77975 7.11L2.83372 5.895L3.3601 4.875C3.46644 4.675 3.6233 4.5125 3.83066 4.3875C4.03802 4.2625 4.264 4.2 4.50858 4.2H5.36993V0.6C5.36993 0.43 5.43108 0.2875 5.55337 0.1725C5.67566 0.0575 5.8272 0 6.00798 0H9.32579C9.44276 0 9.53315 0.0475 9.59696 0.1425C9.66076 0.2375 9.66608 0.335 9.61291 0.435L9.26199 1.065C9.24072 1.115 9.23008 1.16 9.23008 1.2C9.23008 1.24 9.24072 1.285 9.26199 1.335L9.61291 1.965C9.66608 2.065 9.66076 2.1625 9.59696 2.2575C9.53315 2.3525 9.44276 2.4 9.32579 2.4H6.64602V4.2H7.44357C7.68815 4.2 7.91147 4.26 8.11351 4.38C8.31556 4.5 8.47507 4.66 8.59204 4.86L9.15033 5.91L7.2362 7.11L6.29509 6.66C6.21002 6.61 6.11432 6.585 6.00798 6.585C5.90163 6.585 5.80593 6.61 5.72086 6.66ZM0.0741909 11.13L2.25948 6.975L4.38097 8.295C4.47668 8.355 4.58036 8.39 4.69202 8.4C4.80367 8.41 4.91267 8.39 5.01901 8.34L6.00798 7.875L6.99694 8.34C7.10328 8.39 7.21228 8.4125 7.32394 8.4075C7.43559 8.4025 7.53927 8.37 7.63498 8.31L9.72457 7.005L11.9258 11.13C12.0321 11.33 12.0242 11.525 11.9019 11.715C11.7796 11.905 11.5962 12 11.3516 12H0.648428C0.403846 12 0.220409 11.905 0.0981174 11.715C-0.0241738 11.525 -0.0321494 11.33 0.0741909 11.13Z"
        fill="black"
        fillOpacity="0.87"
      />
    </svg>
  );
};

export default TrainingSvg;
