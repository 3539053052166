import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import DonutGraphLegend from './DonutGraphLegend';
import LegendTooltip from './LegendTooltip';
import DonutStacked from './DonutStacked';

const DonutWithLegends = ({
  title,
  valueSegment,
  data,
  colors,
  totalTasks,
}) => {
  const [tooltipData, setTooltipData] = useState(undefined);

  return (
    <Box display="flex" flexDirection="column" mb={2} height="100%">
      {title && (
        <Box mt={2}>
          <FdTypography variant="subtitle1">{title}</FdTypography>
        </Box>
      )}
      {data?.length > 0 ? (
        <Box>
          <Box display="flex" flexDirection="row" my={2}>
            <Box height="200px" width="200px" mr={2}>
              <DonutStacked
                data={data || []}
                colors={colors || []}
                onHover={(_data) => {
                  setTooltipData(_data?.data);
                }}
                onLeave={() => {
                  setTooltipData(undefined);
                }}
              />
            </Box>
            {tooltipData ? (
              <LegendTooltip data={tooltipData} totalTasks={totalTasks} />
            ) : (
              <Box
                width="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                ml={2}
              >
                <FdTypography variant="subtitle1" color="secondary">
                  {` Hover over the ${valueSegment} segment to see more details.`}
                </FdTypography>
              </Box>
            )}
          </Box>
          <DonutGraphLegend data={data || []} />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <FdTypography variant="caption">No data to present</FdTypography>
        </Box>
      )}
    </Box>
  );
};

DonutWithLegends.propTypes = {
  title: PropTypes.string.isRequired,
  valueSegment: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalTasks: PropTypes.string.isRequired,
};

export default DonutWithLegends;
