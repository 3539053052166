export const getAllMentions = (text) => {
  const regex = /@\[([^\]]+)\]/g;
  const matches = [];
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(text)) !== null) {
    matches.push(`@${match[1]}`);
  }

  return matches;
};
