import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography, FdIconsV5 } from '@fifthdomain/fe-shared';

const AccomplishmentView = ({ accomplishment, watchAccomplishment }) => {
  const { School, Stars, WorkspacePremium } = FdIconsV5;

  const viewAccomplishments = {
    'Education/Course': {
      image: <School />,
      title: 'Education/Courses',
    },
    Certification: {
      image: <WorkspacePremium />,
      title: 'Certifications',
    },
    'Other Accomplishment': {
      image: <Stars />,
      title: 'Other',
    },
  };

  return (
    <Box>
      <Box display="flex">
        <Box pr={2}>{viewAccomplishments[accomplishment]?.image}</Box>
        <FdTypography variant="subtitle1">
          {viewAccomplishments[accomplishment]?.title}
        </FdTypography>
      </Box>
      <Box mb={3} ml={5}>
        {watchAccomplishment?.filter((aType) => aType?.type === accomplishment)
          ?.length > 0 ? (
          watchAccomplishment
            ?.filter((aType) => aType?.type === accomplishment)
            ?.map((a) => (
              <Box
                display="flex"
                justifyContent="space-between"
                mt={1}
                key={a?.id}
              >
                <FdTypography color="secondary" variant="body2">
                  {`${
                    a.providerName === undefined || a.providerName === null
                      ? ''
                      : `${a.providerName} | `
                  } `}

                  {`${a.accName === undefined ? '' : a.accName}`}
                </FdTypography>
                <FdTypography color="secondary" variant="body2">
                  {`${
                    a.startYear === undefined || a.startYear === ''
                      ? ''
                      : `${a.startYear} - `
                  } `}

                  {`${a.yearAwarded === undefined ? '' : a.yearAwarded}`}

                  {`${a.endYear === undefined ? '' : a.endYear}`}
                </FdTypography>
              </Box>
            ))
        ) : (
          <FdTypography color="secondary" variant="body2">
            None Added
          </FdTypography>
        )}
      </Box>
    </Box>
  );
};

AccomplishmentView.propTypes = {
  watchAccomplishment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default AccomplishmentView;
