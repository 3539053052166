import React, { useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  AuthContext,
  useSnapshot,
  globalStore,
  Authorization,
  orgDetailsPermissions,
} from '@fifthdomain/fe-shared';
import { getAffiliatedParams } from '../shared/utils/urlUtils';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);
  const affiliatedParams = getAffiliatedParams();

  const hasManagePermissions = Authorization.hasPermission(
    globalSnap?.permissions,
    orgDetailsPermissions,
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        setUser(authUser);
        // add logged in user to global state
        globalStore.user = authUser;
      })
      .catch(() => {
        singleSpa.navigateToUrl('/');
      });
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;
        if (user && type === 'PARTICIPANT') {
          if (affiliatedParams?.isAffiliated) {
            return (
              <Redirect
                to={`/landing/landing-homepage?isAffiliated=${affiliatedParams?.isAffiliated}&orgId=${affiliatedParams?.orgId}`}
              />
            );
          }
          return <Redirect to="/landing/landing-homepage" />;
        }
        if (user && type === 'ADMIN') {
          if (
            window.location.pathname?.includes('org/profile') &&
            !hasManagePermissions
          ) {
            return <Redirect to="/landing" />;
          }
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AdminRoute;
