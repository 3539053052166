import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

const DonutStacked = ({ data, onHover, colors, onLeave }) => {
  return (
    <ResponsivePie
      data={data}
      colors={colors}
      margin={0}
      innerRadius={0.8}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      padAngle={1}
      tooltip={() => <></>}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#fff',
              },
            },
          ],
        },
      ]}
    />
  );
};

DonutStacked.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  colors: PropTypes.arrayOf(PropTypes.string),
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
};

DonutStacked.defaultProps = {
  data: [],
  colors: [],
  onHover: () => null,
  onLeave: () => null,
};

export default DonutStacked;
